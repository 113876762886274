<template>
  <div ref="element">
    {{ emoji }}
  </div>
</template>

<script setup>
import { toRefs, ref, watchEffect } from 'vue'
import animateEmoji from '../lib/animate-emoji'

const props = defineProps(['emoji'])
const element = ref(null)

watchEffect(() => {
  if (element.value) animateEmoji(element.value)
})
</script>