import { createRouter, createWebHistory } from 'vue-router'
import { supabase } from '@/supabase'

import store from '@/store'
import DefaultLayout from '@/layout/Default.vue'
import RoofLayout from '@/layout/RoofLayout.vue'

const router = createRouter({
  history: createWebHistory(),
  scrollBehavior(to, from, savedPosition) {
    if (to.hash) {
      return new Promise(r => {
        // Delay for 600ms to allow transition to happen before trying to find el if changing page
        const delay = to.name === from.name ? 1 : 600;
        setTimeout(() => r({
          el: to.hash,
          behavior: 'smooth',
        }), delay)
      })
    }
    return { top: 0 }
  },
  routes: [
    {
      path: '',
      name: 'Home',
      component: () => import('@/pages/Home.vue'),
    },
    {
      path: '/lobby',
      name: 'Lobby',
      component: () => import('@/pages/Lobby.vue'),
    },
    {
      path: '/roof/login',
      name: 'Login',
      component: () => import('@/pages/roof/Login.vue'),
    },
    {
      path: '/roof/join',
      name: 'Join',
      component: () => import('@/pages/roof/account/Join.vue'),
    },
    {
      path: '/roof',
      component: RoofLayout,
      children: [{
        path: '',
        name: 'Roof',
        meta: { allowAnonymous: true },
        component: () => import('@/pages/roof/Preview.vue'),
        // meta: { sidebar: true },
        // components: {
        //   default: () => import('@/pages/roof/Home.vue'),
        //   right: () => import('@/pages/roof/SidebarHome.vue'),
        // },
      }, {
        path: 'wallet',
        name: 'Wallet',
        meta: { displayName: 'Wallet' },
        component: () => import('@/pages/roof/wallet/Wallet.vue'),
      }, {
        path: 'wallet/withdraw',
        name: 'Withdraw',
        meta: { allowAnonymous: true },
        component: () => import('@/pages/roof/Preview.vue'),
        // meta: { sidebar: true },
        // components: {
        //   default: () => import('@/pages/roof/wallet/Withdraw.vue'),
        //   right: () => import('@/pages/roof/wallet/SidebarWithdraw.vue'),
        // },
      }, {
        path: 'tokens/:id',
        name: 'Token',
        meta: { allowAnonymous: true },
        component: () => import('@/pages/roof/Preview.vue'),
        // component: () => import('@/pages/roof/wallet/Token.vue'),
      },
      {
        path: 'explore',
        meta: { displayName: 'Explore', allowAnonymous: true },
        component: {
          render: () => {
            return (
              <router-view></router-view>
            )
          },
        },
        children: [{
          path: '',
          name: 'Explore',
          redirect: { name: 'ExploreDrops' },
        }, {
          path: 'drops',
          component: () => import('@/pages/roof/explore/Explore.vue'),
          children: [{
            path: '',
            name: 'ExploreDrops',
            meta: { allowAnonymous: true, displayName: 'Diamond Hands Originals' },
            component: () => import('@/pages/roof/explore/drops/Originals.vue'),
          }, {
            path: 'stealth',
            name: 'ExploreStealth',
            meta: { allowAnonymous: true, displayName: 'Stealth Drops' },
            component: () => import('@/pages/roof/explore/drops/Stealth.vue'),
          }, {
            path: 'guest',
            name: 'ExploreGuest',
            meta: { allowAnonymous: true, displayName: 'Guest Pass' },
            component: () => import('@/pages/roof/explore/drops/Guest.vue'),
          }]
        }, {
          path: 'drops/:slug',
          meta: { allowAnonymous: true },
          component: () => import('@/pages/roof/explore/drops/Drop.vue'),
          children: [{
            path: '',
            name: 'ExploreDrop',
            meta: { allowAnonymous: true },
            component: () => import('@/pages/roof/explore/drops/DropMarketing.vue'),
          }, {
            path: 'gallery',
            name: 'DropGallery',
            meta: { allowAnonymous: true, displayName: 'Gallery' },
            component: () => import('@/pages/roof/explore/drops/DropGallery.vue'),
          }]
        }, {
          path: 'updates',
          name: 'HotelUpdates',
          component: () => import('@/pages/roof/explore/Explore.vue'),
        }, {
          path: 'blog',
          name: 'Blog',
          component: () => import('@/pages/roof/explore/Explore.vue'),
        }, {
          path: 'featured',
          name: 'FeaturedProjects',
          component: () => import('@/pages/roof/explore/Explore.vue'),
        }]
      },
      {
        path: 'games',
        meta: { allowAnonymous: true, displayName: 'Games' },
        component: () => import('@/pages/roof/games/Games.vue'),
        children: [{
          path: '',
          name: 'Games',
          redirect: { name: 'FlappySnek' }
        }, {
          path: 'flappysnek',
          name: 'FlappySnek',
          meta: { allowAnonymous: true, displayName: 'Flappy Snek' },
          component: () => import('@/pages/roof/games/flappy/FlappySnek.vue'),
        }]
      }, {
        path: 'graffiti',
        name: 'GraffitiWall',
        meta: { allowAnonymous: true },
        component: () => import('@/pages/roof/Preview.vue'),
        // component: () => import('@/pages/roof/graffiti/Wall.vue'),
      }, {
        path: 'rooms',
        name: 'Rooms',
        meta: { allowAnonymous: true },
        component: () => import('@/pages/roof/Preview.vue'),
        // meta: { sidebar: true },
        // components: {
        //   default: () => import('@/pages/roof/rooms/Rooms.vue'),
        //   right: () => import('@/pages/roof/rooms/SidebarRooms.vue'),
        // },
        // children: [{
        //   path: ':slug',
        //   name: 'Room',
        //   component: () => import('@/pages/roof/rooms/Room.vue'),
        // }]
      }, {
        path: 'chat',
        name: 'Chat',
        meta: { allowAnonymous: true },
        component: () => import('@/pages/roof/Preview.vue'),
        // meta: { sidebar: true, reverse: true },
        // components: {
        //   default: () => import('@/pages/roof/chat/Chat.vue'),
        //   right: () => import('@/pages/roof/chat/SidebarChat.vue'),
        // },
      }, {
        path: 'account',
        meta: { displayName: 'Account' },
        component: () => import('@/pages/roof/account/Account.vue'),
        children: [{
          path: '',
          name: 'Account',
          meta: { displayName: 'Profile' },
          component: () => import('@/pages/roof/account/Profile.vue')
        }, {
          path: 'wallets',
          name: 'LinkWallet',
          meta: { displayName: 'Wallets' },
          component: () => import('@/pages/roof/account/Wallets.vue')
        }, {
          path: 'discord',
          name: 'LinkDiscord',
          meta: { displayName: 'Discord' },
          component: () => import('@/pages/roof/account/Discord.vue')
        }],
      },
      {
        path: 'billing',
        name: 'Billing',
        redirect: { name: 'Account' },
      }]
    },
    {
      path: '',
      component: DefaultLayout,
      children: [{
        path: 'drops',
        name: 'Drops',
        component: () => import('@/pages/Drops.vue'),
      },
      {
        path: '/legal/terms',
        name: 'TermsConditions',
        component: () => import('@/pages/legal/Terms.vue'),
      },
      {
        path: '/legal/privacy',
        name: 'PrivacyPolicy',
        component: () => import('@/pages/legal/Privacy.vue'),
      }]
    },
  ],
})

router.beforeEach((to, from, next) => {
  const id = store.getters['auth/id']
  const [first] = to.matched
  // All non-roof paths are unauthenticated
  if (id || first.path !== '/roof') return next()
  // Route doesn't require authentication
  if (to.meta.allowAnonymous) return next()
  // Authentication is required
  return next({ name: 'Login', query: { next: encodeURIComponent(to.fullPath) } })
})

export default router