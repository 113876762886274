<template>
  <div class="py-1">
    <MenuItem v-slot="{ active }">
      <button @click="walletId = peerId" :class="[active ? 'bg-gray-100 text-gray-900' : 'text-gray-700', 'group flex items-center px-4 py-2 text-xs block w-full']">
        <CollectionIcon class="mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500" aria-hidden="true" />
        Wallet
      </button>
    </MenuItem>
    <MenuItem v-if="twitterIdentity" v-slot="{ active }">
      <a :href="`https://twitter.com/${twitterIdentity.id.replace('@', '')}`" target="_blank"
        rel="noreferrer" :class="[active ? 'bg-gray-100 text-gray-900' : 'text-gray-700', 'group flex items-center px-4 py-2 text-xs block w-full']">
        <TwitterIcon class="mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500" aria-hidden="true" />
        @{{ twitterIdentity.id.replace('@', '') }}
      </a>
    </MenuItem>
  </div>
</template>

<script setup>
import { toRefs, computed } from 'vue'
import { useStore } from 'vuex'
import { MenuItem } from '@headlessui/vue'
import { TwitterIcon } from '@ui'
import { CollectionIcon } from '@heroicons/vue/outline'

const props = defineProps(['peerId', 'info'])
const { peerId } = toRefs(props)

const twitterIdentity = computed(() => props.info?.identities?.find(i => i.type === 'twitter'));

const store = useStore()
const walletId = computed({
  get: () => store.getters['talk/walletModalId'],
  set: (val) => store.commit('talk/MODAL_WALLET', val)
})
</script>