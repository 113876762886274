

export default {
  namespaced: true,
  state: {
    open: false,
    modal: {
      editIdentity: false,
      editRoom: false,
      wallet: {
        open: false,
        talkId: null,
      },
    }
  },
  getters: {
    open: ({ open }) => open,
    editIdentityModal: ({ modal }) => modal.editIdentity,
    editRoomModal: ({ modal }) => modal.editRoom,
    walletModalOpen: ({ modal }) => modal.wallet.open,
    walletModalId: ({ modal }) => modal.wallet.talkId,
  },
  actions: {
    
  },
  mutations: {
    SET_OPEN(state, open) {
      state.open = open
    },
    MODAL_IDENTITY(state, open) {
      state.modal.editIdentity = !!open
    },
    MODAL_EDIT_ROOM(state, open) {
      state.modal.editRoom = !!open
    },
    MODAL_WALLET(state, talkId) {
      if (talkId) {
        state.modal.wallet.talkId = talkId
        state.modal.wallet.open = true
      } else {
        state.modal.wallet.talkId = null
        state.modal.wallet.open = false
      }
    },
  }
}