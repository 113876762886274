import NodeCache from 'node-cache'
import { ref } from 'vue'
import { supabase } from '@/supabase'
import store from '@/store'

class RestService {
  constructor() {
    this.BASE_URL = 'http://localhost:8787'
    this.cache = new NodeCache({ stdTTL: 10 })
  }

  _useRestQuery({ defaultValue = null, immediate = true, key }, context) {
    const loading = ref(false)
    const error = ref(null)
    const data = ref(defaultValue)

    const load = async () => {
      error.value = null
      try {
        const { error, ...data } = await context(this._fetch.bind(this))
        if (error) throw new Error(error)
        if (key) return data[key]
        if (Object.entries(data).length === 1) {
          // Only contains one key, return
          return Object.values(data)[0]
        }
        return data
      } catch (err) {
        error.value = err
        return null
      }
    }

    const refresh = async () => {
      loading.value = true
      const res = await load()
      data.value = res
      loading.value = false
      return res
    }
    const promise = immediate ? refresh() : undefined

    return { loading, error, data, refresh, promise }
  }

  _useCache({ key, ttl = 0 }, context) {
    // Shim to cache all data
    const refresh = async (force = false) => {
      const res = this.cache.get(key)
      if (res && !force) return res

      const data = await context.refresh()
      this.cache.set(key, data, ttl)
      return data
    }

    const load = async () => {
      context.data.value = await refresh()
    }

    return { ...context, refresh, promise: load() }
  }

  async _fetch(endpoint, body = undefined, method = 'POST') {
    const { access_token: token } = supabase.auth.session()
    const args = {
      method,
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`,
      },
    }

    if (body) args.body = JSON.stringify(body)

    const res = await fetch(`${this.BASE_URL}${endpoint}`, args)
    return res.json()
  }
}

export default RestService