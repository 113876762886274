import AuthService from '@/services/auth.service'

const playerObject = data => ({
  id: data[0],
  posX: data[1],
  posY: data[2],
  rotation: data[3],
  score: data[4],
  state: data[5],
  displayName: data[6],
  avatarImg: data[7],
  color: data[8], 
})

const gameObject = data => ({
  id: data[0],
  status: data[1],
  startAt: data[2],
  practiceMode: data[3],
  nextRound: data[4]
})

export default {
  namespaced: true,
  state: {
    game: {
      id: null,
      startAt: null,
      status: null,
      practiceMode: null,
      nextRound: null,
    },
    stats: {
      online: null,
      alive: null,
    },
    player: {
      joined: false,
    },
    winner: null,
    events: [],
  },
  getters: {
    game: state => state.game,
    winner: state => state.winner,
    stats: state => state.stats,
    joined: ({ player }) => player.joined,
    inLiveGame: ({ game }) => game.status === 'ACTIVE' && !game.practiceMode,
    inPracticeGame: ({ game }) => game.status === 'ACTIVE' && game.practiceMode,
    events: state => {
      // If pending or starting soon show join events
      if (['PENDING', 'STARTING_SOON'].includes(state.game.status)) {
        return state.events
          .filter(({ type }) => type === 'joined')
          .slice(-4)
          .reverse()
      }

      // If active or ended show elimination events
      return state.events
        .filter(({ type }) => type === 'eliminated')
        .slice(-4)
        .reverse()
    },
  },
  actions: {
    joined({ commit }) {
      commit('SET_JOINED', true)
    },
    left({ commit }) {
      commit('SET_JOINED', false)
    },
    loadPlayers({ commit }, players) {
      for (let i = 0; i < players.length; i++) {
        commit('PLAYER_JOINED', players[i])
      }
    },
    setGameState({ commit }, data) {
      commit('SET_GAME_STATE', data)
    },
    setStats({ commit }, data) {
      commit('SET_STATS', data)
    },
    addPlayer({ commit }, data) {
      commit('PLAYER_JOINED', data)
    },
    eliminatePlayer({ commit }, [player, rank]) {
      commit('PLAYER_ELIMINATED', [player, rank])
    },
  },
  mutations: {
    SET_JOINED(state, joined) {
      // TODO
      state.player.joined = joined
    },
    SET_GAME_STATE(state, data) {
      const game = gameObject(data)
      console.log('mutation:SET_GAME_STATE', game)
      // Only reset join if there was a previous game set
      if (state.game.id && state.game.id !== game.id) {
        state.player.joined = false
        state.stats = { online: null, alive: null }
        state.winner = null
        state.events = []
      }

      state.game = game
      state.game.startAt = new Date(`${game.startAt}Z`)
    },
    SET_STATS(state, data) {
      const [id, [online, alive]] = data
      console.log('mutation:SET_STATS')
      state.stats = { online, alive }
    },
    PLAYER_JOINED(state, data) {
      const player = playerObject(data)
      console.log('mutation:PLAYER_JOINED', player)
      state.events.push({ type: 'joined', player })
    },
    PLAYER_ELIMINATED(state, [player, rank]) {
      console.log('mutation:PLAYER_ELIMINATED', player)
      state.events.push({ type: 'eliminated', player, rank })
      state.stats.alive = rank
    },
  }
}