/* global _gs */
import { isJwtExpired } from 'jwt-check-expiration'
import { notify } from '@kyvg/vue3-notification'
import { ownProfile } from '@/gql/auth'
import { apolloClient } from '@/services/apollo'
import AuthService from '@/services/auth.service'

const validateAndReturn = (token, res) => {
  if (!token) return null
  return isJwtExpired(token) ? null : res
}

export default {
  namespaced: true,
  state: {
    token: null,
    user: null,
    address: null,
    connectWalletModal: false,
    lastProvider: null,
    activeWallet: null,
  },
  getters: {
    address: state => {
      if (state.token && !isJwtExpired(state.token)) return state.address
      return null
    },
    token: state => {
      if (state.token && !isJwtExpired(state.token)) return state.token
      return null
    },
    user: state => {
      if (state.token && !isJwtExpired(state.token)) return state.user
      return null
    },
    id: state => {
      if (state.token && !isJwtExpired(state.token)) return state.user.id
      return null
    },
    activeWallet: state => {
      if (state.token && !isJwtExpired(state.token)) return state.activeWallet
      return null
    },
    connectWalletModal: state => state.connectWalletModal,
  },
  actions: {
    async loginWithWeb3({ commit, dispatch }, chainName) {
      let result
      switch (chainName) {
        case 'SOLANA':
          // login with phantom
          result = await dispatch('loginWithPhantom')
          break
        case 'ETHEREUM':
          // login with metamask
          result = await dispatch('loginWithMetamask')
          break
        default:
          throw new Error('Unknown chainName')
      }

      // Fetch the profile
      const { data: { profile } } = await apolloClient.query({
        query: ownProfile,
        variables: { id: result.user.id },
      });

      // Find the wallet for this chain
      const wallet = profile.wallets.find(wallet => wallet.chainName === chainName)
      commit('SET_ACTIVE_WALLET', wallet)

      return {
        ...result,
        user: profile
      }
    },

    async loginWithPhantom({ commit, dispatch }) {
      if (!window.solana) {
        commit('SET_CONNECT_WALLET_MODAL', 'SOLANA')
        throw new Error('Phantom not available')
      }

      try {
        const { address, token, user } = await AuthService.connectPhantom()
        commit('SET_AUTH_STATE', { address, token, user })
        return { address, token, user }
      } catch (err) {
        if (err.code === 4001) {
          // User rejected the request
          throw new Error('You rejected the request')
        }

        console.log(err)
        commit('SET_AUTH_STATE', { token: null, user: null })
        throw err
      }
    },

    async loginWithMetamask({ commit, dispatch }) {
      if (!window.ethereum) {
        commit('SET_CONNECT_WALLET_MODAL', 'ETHEREUM')
        throw new Error('Metamask not available')
      }

      try {
        const { address, token, user } = await AuthService.connectMetamask()
        commit('SET_AUTH_STATE', { address, token, user })
        return { address, token, user }
      } catch (err) {
        if (err.code === 4001) {
          // User rejected the request
          throw new Error('You rejected the request')
        }
        
        console.log(err)
        commit('SET_AUTH_STATE', { token: null, user: null })
        throw err
      }
    },
  },
  mutations: {
    SET_AUTH_STATE(state, { address, token, user }) {
      state.token = token
      state.user = user
      state.address = address
      _gs('identify', { id: user.id, username: user.displayName })
    },
    SET_CONNECT_WALLET_MODAL(state, show) {
      state.connectWalletModal = show
    },
    SET_ACTIVE_WALLET(state, wallet) {
      state.activeWallet = wallet
    },
    LOGOUT(state) {
      state.token = null
      state.user = null
      state.address = null
      _gs('unidentify')
    }
  }
}