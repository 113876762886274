<template>
  <div class="bg-gradient-to-r from-pink-600 to-purple-600 h-2"></div>
  <template v-if="loading">
    <div class="px-6 pt-3 pb-4 bg-white shadow">
      <div class="flex items-center justify-between">
        <DialogTitle class="text-xl font-bold text-gray-900 sm:text-2xl">
          Loading...
        </DialogTitle>
        <div class="ml-3 h-7 flex items-center space-x-4">
          <slot name="title-append"></slot>
        </div>
      </div>
    </div>
    <div class="flex-1 overflow-y-auto p-4 sm:p-6">
      <div class="grid grid-cols-2 gap-6">
        <div v-for="n in 16" :key="n" class="group animate-pulse">
          <div class="w-full aspect-w-1 aspect-h-1 bg-gray-200 rounded-lg overflow-hidden"></div>
          <div class="mt-4 bg-gray-200 h-4 w-2/3 rounded"></div>
          <div class="mt-1 bg-gray-200 h-6 w-full rounded"></div>
        </div>
      </div>
    </div>
  </template>
  <template v-else-if="wallets.length && activeWallet">
    <div class="px-6 pt-3 pb-4 bg-white shadow">
      <div class="flex items-center justify-between">
        <Menu as="div" class="relative inline-block text-left">
          <div>
            <MenuButton class="group w-full">
              <div class="flex items-center justify-between space-x-3">
                <span>
                  <EthIcon v-if="activeWallet.chainName === 'ETHEREUM'" class="inline h-6" aria-hidden="true" />
                  <SolanaIcon v-if="activeWallet.chainName === 'SOLANA'" class="inline h-5" aria-hidden="true" />
                </span>
                <DialogTitle class="text-xl font-bold text-gray-900 sm:text-2xl">
                  {{ $filters.addressFmt(activeWallet.address) }}
                </DialogTitle>
                <span>
                  <SelectorIcon class="h-6 w-6 text-gray-400 group-hover:text-gray-500" aria-hidden="true" />
                </span>
              </div>
            </MenuButton>
          </div>

          <transition enter-active-class="transition ease-out duration-100" enter-from-class="transform opacity-0 scale-95" enter-to-class="transform opacity-100 scale-100" leave-active-class="transition ease-in duration-75" leave-from-class="transform opacity-100 scale-100" leave-to-class="transform opacity-0 scale-95">
            <MenuItems class="z-10 mx-3 origin-top absolute right-0 left-0 mt-1 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 divide-y divide-gray-200 focus:outline-none">
              <div class="py-1">
                <MenuItem v-for="wallet in wallets" :key="wallet.id" v-slot="{ active }">
                  <button @click="activeWallet = wallet" :class="[active ? 'bg-gray-100 text-gray-900' : 'text-gray-700', 'block w-full text-left px-4 py-2 text-sm']">
                    <EthIcon v-if="wallet.chainName === 'ETHEREUM'" class="inline mr-3 h-4 w-4" aria-hidden="true" />
                    <SolanaIcon v-if="wallet.chainName === 'SOLANA'" class="inline mr-3 h-3 w-4" aria-hidden="true" />
                    {{ $filters.addressFmt(wallet.address) }}
                  </button>
                </MenuItem>
              </div>
            </MenuItems>
          </transition>
        </Menu>

        <div class="ml-3 h-7 flex items-center space-x-4">
          <slot name="title-append"></slot>
        </div>
      </div>
    </div>

    <slot :wallet="activeWallet"></slot>
  </template>
  <template v-else>
    <div class="px-6 pt-3 pb-4 bg-white shadow">
      <div class="flex items-center justify-between">
        <DialogTitle class="text-xl font-bold text-gray-900 sm:text-2xl">
          No wallets
        </DialogTitle>
        <div class="ml-3 h-7 flex items-center space-x-4">
          <slot name="title-append"></slot>
        </div>
      </div>
    </div>
    <div class="p-4 sm:p-6">
      <div class="relative block w-full border-2 border-gray-300 border-dashed rounded-lg p-12 text-center">
        <CollectionIcon class="mx-auto h-10 w-10 text-gray-400" />
        <span class="mt-2 block text-sm font-medium text-gray-900">
          This user hasn't linked any wallets
        </span>
      </div>
    </div>
  </template>
</template>

<script setup>
import { computed, ref, watch } from 'vue'
import { useQuery, useResult } from '@vue/apollo-composable'
import { useStore } from 'vuex'
import { DialogTitle, Menu, MenuButton, MenuItems, MenuItem } from '@headlessui/vue'
import { XIcon, SelectorIcon, CollectionIcon } from '@heroicons/vue/outline'
import { walletsByTalkId } from '@/gql/wallet'
import { EthIcon, SolanaIcon } from '@ui'

const store = useStore()
const talkId = computed(() => store.getters['talk/walletModalId'])

const activeWallet = ref(null)
const { result, loading, refetch, onResult } = useQuery(
  walletsByTalkId,
  () => ({ id: talkId.value }),
  () => ({
    enabled: !!talkId.value,
    fetchPolicy: 'no-cache',
  })
)

const wallets = useResult(result, [], ({ wallets }) => wallets)
const setInitialWallet = () => {
  if (wallets.value.length > 0) {
    // try to set to solana, otherwise use first result
    activeWallet.value = wallets.value.find(({ chainName }) => chainName === 'SOLANA')
    console.log('setInitialWallet', activeWallet.value)
  }
}

// On result is only called on the first load
if (wallets.length) setInitialWallet()
onResult(setInitialWallet)
</script>