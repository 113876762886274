<template>
  <UserDropdown v-if="info" :peer-id="peerId" :info="info">
    <li class="flex-none m-2 w-16 md:w-24 text-sm">
      <div class="relative flex justify-center">
        <img class="rounded-full shadow w-16 h-16 md:w-24 h-auto border border-gray-300 object-cover pointer-events-none" :src="avatarUrl(info, room)">

        <Reactions :reactions="reactions" classes="absolute bg-white flex items-center justify-center text-3xl md:text-4xl w-12 h-12 sm:w-16 sm:h-16 rounded-full border" />

        <div v-if="handRaised">
          <div class="absolute w-6 h-6 md:w-8 md:h-8 top-0 left-0 rounded-full bg-pink-700 flex items-center justify-center">
            <HandIcon class="w-3.5 h-3.5 md:w-5 md:h-5 text-white" />
          </div>
        </div>
      </div>

      <div class="mt-2 flex items-center justify-center">
        <BadgeCheckIcon v-if="isModerator" class="mr-1 w-4 h-4 text-purple-600" />
        <TwitterIcon v-else-if="twitterIdentity" class="mr-1 w-4 h-4 text-blue-600" />
        <div class="overflow-hidden whitespace-nowrap text-center truncate">
          {{ displayName(info, room) }}
        </div>
      </div>
    </li>
  </UserDropdown>
</template>

<script setup>
import { computed, toRefs, reactive } from 'vue'
import { HandIcon, BadgeCheckIcon } from '@heroicons/vue/solid'
import { TwitterIcon } from '@ui'
import { useJam } from '../lib/use-jam'
import { avatarUrl, displayName } from '../lib/avatar'

import UserDropdown from './UserDropdown.vue'
import Reactions from './Reactions.vue'

const { state } = useJam()
const props = defineProps([
  'room',
  'peerId',
  'peerState',
  'reactions',
  'info',
  'moderator',
  'moderators',
])

const {
  room,
  peerId,
  moderator,
  moderators,
  peerState,
  reactions: _reactions,
  info: _info,
} = toRefs(props)
const { myId, handRaised: myHandRaised } = toRefs(state)

const isMe = computed(() => peerId.value === myId.value)
const reactions = computed(() => state.reactions[peerId.value])
const info = computed(() => _info.value || ({ id: peerId.value }))
const twitterIdentity = computed(() => info?.value.identities?.find(i => i.type === 'twitter'))
const isModerator = computed(() => moderators.value.includes(peerId.value))
const handRaised = computed(() => ((peerId.value === myId.value) && myHandRaised.value) || (moderator.value && peerState.value?.handRaised))
</script>