<template>
  <TransitionRoot as="template" :show="open">
    <Dialog as="div" class="fixed z-10 inset-0 overflow-y-auto" @close="open = false">
      <div class="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
        <TransitionChild as="template" enter="ease-out duration-300" enter-from="opacity-0" enter-to="opacity-100" leave="ease-in duration-200" leave-from="opacity-100" leave-to="opacity-0">
          <DialogOverlay class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </TransitionChild>

        <!-- This element is to trick the browser into centering the modal contents. -->
        <span class="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>
        <TransitionChild as="template" enter="ease-out duration-300" enter-from="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95" enter-to="opacity-100 translate-y-0 sm:scale-100" leave="ease-in duration-200" leave-from="opacity-100 translate-y-0 sm:scale-100" leave-to="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95">
          <div class="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-sm sm:w-full sm:p-6">
            <div>
              <div class="mx-auto flex items-center justify-center h-12 w-12 rounded-full bg-purple-100">
                <UserIcon class="h-6 w-6 text-purple-600" aria-hidden="true" />
              </div>
              <div class="mt-3 text-center sm:mt-5">
                <DialogTitle as="h3" class="text-lg leading-6 font-medium text-gray-900">
                  Edit your name
                </DialogTitle>
                <div class="mt-2">
                  <p class="text-sm text-gray-500">
                    To change your profile picture, <AppLink :to="{ name: 'Login' }" class="font-medium text-purple-600 focus:outline-none">sign in with your wallet</AppLink> and <AppLink :to="{ name: 'Account' }" class="font-medium text-purple-600 focus:outline-none">update your profile</AppLink>.
                  </p>
                </div>
                <div class="mt-6 text-left border border-gray-300 rounded-md px-3 py-2 shadow-sm focus-within:ring-1 focus-within:ring-purple-600 focus-within:border-purple-600">
                  <label for="name" class="block text-xs font-medium text-gray-900">Display name</label>
                  <input type="text" id="name" v-model="name" class="block w-full border-0 p-0 text-gray-900 placeholder-gray-500 focus:ring-0 sm:text-sm" placeholder="Jane Doe" autofocus />
                </div>
              </div>
            </div>
            <div class="mt-4 sm:mt-5">
              <button type="button" class="inline-flex justify-center w-full rounded-md border border-transparent shadow-sm px-4 py-2 bg-purple-600 text-base font-medium text-white hover:bg-purple-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-purple-500 sm:text-sm" @click="save">
                Save
              </button>
            </div>
          </div>
        </TransitionChild>
      </div>
    </Dialog>
  </TransitionRoot>
</template>

<script setup>
import { ref, computed, toRefs, watch } from 'vue'
import { Dialog, DialogOverlay, DialogTitle, TransitionChild, TransitionRoot } from '@headlessui/vue'
import { UserIcon } from '@heroicons/vue/outline'
import AppLink from '@/components/roof/AppLink.vue'
import { useStore } from 'vuex'
import { useJam } from './lib/use-jam'

const store = useStore()
const { state, api } = useJam()
const { myId, myIdentity } = toRefs(state)
const name = ref(myIdentity.value?.info?.name)

const open = computed({
  get: () => store.getters['talk/editIdentityModal'],
  set: (val) => store.commit('talk/MODAL_IDENTITY', val)
})

const save = async () => {
  await api.updateInfo({ name: name.value })
  open.value = false
}
</script>