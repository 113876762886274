<template>
  <Header :title="roomName || 'The Bar'" />
  <template v-if="inRoom">
    <Room :room-id="inRoom" />
  </template>
  <template v-else>
    <InfoCard />
    <div class="mt-4 flex-1 flex flex-col">
      <RoomsList />
    </div>    
  </template>
</template>

<script setup>
import { toRefs, computed } from 'vue'
import { useJam } from '../lib/use-jam'
import Header from '../ui/Header.vue'
import InfoCard from '../discovery/InfoCard.vue'
import RoomsList from '../discovery/RoomsList.vue'
import Room from '../ui/Room.vue'

const { state, api } = useJam()
const { inRoom, room } = toRefs(state)

const roomName = computed(() => inRoom.value && room.value?.name)
</script>