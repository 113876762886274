<template>
  <AnimatedEmoji
    v-for="[e, id] in reactions"
    :emoji="e"
    :key="id"
    :class="classes" />
</template>

<script setup>
import { computed, toRefs } from 'vue'
import { reactionEmojis } from '../lib/config'
import AnimatedEmoji from './AnimatedEmoji.vue'

const props = defineProps(['reactions', 'classes'])
const {
  classes,
  reactions: _reactions
} = toRefs(props)

const reactions = computed(() => _reactions.value?.filter(([e]) => reactionEmojis.includes(e)))
</script>