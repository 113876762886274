<template>
  <div class="bg-gradient-to-r from-pink-600 to-purple-600 h-2"></div>
  <div class="px-6 pt-3 pb-4 bg-white shadow">
    <div class="flex items-center justify-between">
      <DialogTitle class="text-xl font-bold text-gray-900 sm:text-2xl">
        {{ title }}
      </DialogTitle>
      <div class="ml-3 h-7 flex items-center space-x-4">
        <!-- <RecordBtn /> -->
        <button v-if="isModerator && inRoom" @click="editOpen = true" type="button" class="rounded-md text-gray-400 hover:text-gray-500 focus:ring-2 focus:ring-purple-600">
          <span class="sr-only">Settings</span>
          <CogIcon class="h-6 w-6" aria-hidden="true" />
        </button>
        <button @click="open = false" type="button" class="rounded-md text-gray-400 hover:text-gray-500 focus:ring-2 focus:ring-purple-600">
          <span class="sr-only">Close panel</span>
          <XIcon class="h-6 w-6" aria-hidden="true" />
        </button>
      </div>
    </div>
  </div>
</template>

<script setup>
import { toRefs, computed } from 'vue'
import { useStore } from 'vuex'
import { DialogTitle } from '@headlessui/vue'
import { XIcon, CogIcon } from '@heroicons/vue/outline'
import { ChevronRightIcon } from '@heroicons/vue/solid'
import { useJam } from '../lib/use-jam'

import RecordBtn from './RecordBtn.vue'

defineProps({ title: String })

const { state } = useJam()
const { myId, room, inRoom } = toRefs(state)

const isModerator = computed(() => room.value.moderators.includes(myId.value))

const store = useStore()
const open = computed({
  get: () => store.getters['talk/open'],
  set: (val) => store.commit('talk/SET_OPEN', val)
})

const editOpen = computed({
  get: () => store.getters['talk/editRoomModal'],
  set: (val) => store.commit('talk/MODAL_EDIT_ROOM', val)
})
</script>