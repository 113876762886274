<template>
  <UserDropdown v-if="inRoom && info" :peer-id="peerId" :info="info">
    <li class="relative items-center space-y-1 mx-1.5 cursor-pointer">
      <div :class="isSpeaking ? 'rounded-full p-1 bg-gradient-to-tr from-pink-600 to-purple-600' : 'rounded-full p-1 bg-transparent'">
        <div class="bg-white rounded-full p-1 relative flex justify-center">
          <img class="shadow rounded-full w-20 h-20 md:w-28 md:h-28 object-cover pointer-events-none" :src="avatarUrl(info, room)">
          <Reactions :reactions="reactions" classes="absolute bg-white flex items-center justify-center text-4xl md:text-5xl w-14 h-14 sm:w-20 sm:h-20 rounded-full border" />
        </div>


        <div v-if="micMuted || !canSpeak" class="absolute w-6 h-6 md:w-8 md:h-8 right-1 top-16 md:right-0 md:top-20 rounded-full bg-purple-700 text-2xl flex items-center justify-center">
          <VolumeOffIcon class="w-3.5 h-3.5 md:w-5 md:h-5 text-white" />
        </div>

      </div>

      <div class="mt-2 text-sm md:text-base font-medium flex items-center justify-center">
        <BadgeCheckIcon v-if="isModerator" class="flex-none text-purple-600 inline-block w-4 h-4 mr-1" />
        <TwitterIcon v-else-if="twitterIdentity" class="mr-1 w-4 h-4 text-blue-600" />
        <span class="overflow-hidden whitespace-nowrap truncate">
          {{ displayName(info, room) }}
        </span>
      </div>
    </li>
  </UserDropdown>
</template>

<script setup>
import { Menu, MenuButton } from '@headlessui/vue'

import { computed, toRefs, watchEffect } from 'vue'
import { BadgeCheckIcon, VolumeOffIcon } from '@heroicons/vue/solid'
import { MicrophoneOffIcon, TwitterIcon } from '@ui'
import { avatarUrl, displayName } from '../lib/avatar'

import UserDropdown from './UserDropdown.vue'
import Reactions from './Reactions.vue'
// import TwitterHandle from './TwitterHandle.vue'

const props = defineProps([
  'room',
  'speaking',
  'canSpeak',
  'moderators',
  'peerId',
  'peerState',
  'reactions',
  'info',
])

const {
  room,
  speaking,
  canSpeak,
  moderators,
  peerId,
  peerState,
  reactions: reactions_,
  info,
} = toRefs(props)

const reactions = computed(() => reactions_.value[peerId.value])
const isSpeaking = computed(() => speaking.value.includes(peerId.value))
const isModerator = computed(() => moderators.value.includes(peerId.value))
const micMuted = computed(() => props.peerState?.micMuted)
const inRoom = computed(() => props.peerState?.inRoom)
const twitterIdentity = computed(() => info?.value.identities?.find(i => i.type === 'twitter'))
</script>