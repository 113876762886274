import { onMounted, reactive } from 'vue'
import resolveConfig from 'tailwindcss/resolveConfig'
import tailwindConfig from '../../../tailwind.config.js'

const config = resolveConfig(tailwindConfig)
const screens = Object.entries(config.theme.screens)
  .map(([name, size]) => ({ name, size: parseInt(size.replace('px', '')) }))
  .reduce((acc, cur) => {
    acc[cur.name] = cur.size
    return acc
  }, {})

const xl2 = val => val >= screens['2xl']
const xl = val => val >= screens.xl
const lg = val => val >= screens.lg
const md = val => val >= screens.md
const sm = val => val >= screens.sm

const getBreakpoint = (w) => {
  if (xl2(w)) return '2xl'
  if (xl(w)) return 'xl'
  if (lg(w)) return 'lg'
  if (md(w)) return 'md'
  if (sm(w)) return 'sm'
  return 'xs'
}

const breakpoint = reactive({
  w: 0,
  h: 0,
  is: 'xs',
  xlAndUp: false,
  lgAndUp: false,
  mdAndUp: false,
  smAndUp: false,
  xlAndDown: false,
  lgAndDown: false,
  mdAndDown: false,
  smAndDown: false,
  '2xl': false,
  xl: false,
  lg: false,
  md: false,
  sm: false,
  xs: false,
})

const setBreakpoint = () => {
  breakpoint.w = window.innerWidth
  breakpoint.h = window.innerHeight
  breakpoint.is = getBreakpoint(window.innerWidth)

  breakpoint.xlAndUp = xl(window.innerWidth)
  breakpoint.lgAndUp = lg(window.innerWidth)
  breakpoint.mdAndUp = md(window.innerWidth)
  breakpoint.smAndUp = sm(window.innerWidth)

  breakpoint['2xl'] = xl2(window.innerWidth)
  breakpoint.xl = breakpoint.xlAndUp && !breakpoint['2xl']
  breakpoint.lg = breakpoint.lgAndUp && !breakpoint.xlAndUp
  breakpoint.md = breakpoint.mdAndUp && !breakpoint.lgAndUp
  breakpoint.sm = breakpoint.smAndUp && !breakpoint.mdAndUp
  breakpoint.xs = !breakpoint.smAndUp

  breakpoint.xlAndDown = breakpoint.xs || breakpoint.sm || breakpoint.md || breakpoint.lg || breakpoint.xl
  breakpoint.lgAndDown = breakpoint.xs || breakpoint.sm || breakpoint.md || breakpoint.lg
  breakpoint.mdAndDown = breakpoint.xs || breakpoint.sm || breakpoint.md
  breakpoint.smAndDown = breakpoint.xs || breakpoint.sm
}

const useBreakpoint = () => {
  onMounted(() => {
    setBreakpoint()
    window.addEventListener('resize', () => {
      setBreakpoint()
    })
  })

  return breakpoint
}

export default useBreakpoint