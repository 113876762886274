import { defineComponent, h } from 'vue'

export { default as MetamaskIcon } from './svg/metamask.vue'
export { default as PhantomIcon } from './svg/phantom.vue'
export { default as SolanaIcon } from './svg/solana.vue'
export { default as DHHIcon } from './svg/dhh.vue'
export { default as GhostIcon } from './svg/ghost.vue'
export { default as FriendsIllustration } from './svg/friends.vue'
export { default as VipIcon } from './svg/vip.vue'
export { default as MicrophoneOffIcon } from './svg/microphone-off.vue'
export { default as RecordStartIcon } from './svg/record-start.vue'
export { default as RecordEndIcon } from './svg/record-end.vue'

export const EthIcon = defineComponent({
  render: () =>
    h('svg', { viewBox: '0 0 256 417' }, [
      h('path', {
        fill: '#343434',
        d: 'M127.961 0l-2.795 9.5v275.668l2.795 2.79 127.962-75.638z',
      }),
      h('path', {
        fill: '#8C8C8C',
        d: 'M127.962 0L0 212.32l127.962 75.639V154.158z',
      }),
      h('path', {
        fill: '#3C3C3B',
        d: 'M127.961 312.187l-1.575 1.92v98.199l1.575 4.6L256 236.587z',
      }),
      h('path', {
        fill: '#8C8C8C',
        d: 'M127.962 416.905v-104.72L0 236.585z',
      }),
      h('path', {
        fill: '#141414',
        d: 'M127.961 287.958l127.96-75.637-127.96-58.162z',
      }),
      h('path', {
        fill: '#393939',
        d: 'M0 212.32l127.96 75.638v-133.8z',
      }),
    ]),
})

export const TwitterIcon = defineComponent({
  render: () =>
    h('svg', { fill: 'currentColor', viewBox: '0 0 24 24' }, [
      h('path', {
        d:
          'M8.29 20.251c7.547 0 11.675-6.253 11.675-11.675 0-.178 0-.355-.012-.53A8.348 8.348 0 0022 5.92a8.19 8.19 0 01-2.357.646 4.118 4.118 0 001.804-2.27 8.224 8.224 0 01-2.605.996 4.107 4.107 0 00-6.993 3.743 11.65 11.65 0 01-8.457-4.287 4.106 4.106 0 001.27 5.477A4.072 4.072 0 012.8 9.713v.052a4.105 4.105 0 003.292 4.022 4.095 4.095 0 01-1.853.07 4.108 4.108 0 003.834 2.85A8.233 8.233 0 012 18.407a11.616 11.616 0 006.29 1.84',
      }),
    ]),
})

export const InstagramIcon = defineComponent({
  render: () =>
    h('svg', { fill: 'currentColor', viewBox: '0 0 24 24' }, [
      h('path', {
        'fill-rule': 'evenodd',
        d:
          'M12.315 2c2.43 0 2.784.013 3.808.06 1.064.049 1.791.218 2.427.465a4.902 4.902 0 011.772 1.153 4.902 4.902 0 011.153 1.772c.247.636.416 1.363.465 2.427.048 1.067.06 1.407.06 4.123v.08c0 2.643-.012 2.987-.06 4.043-.049 1.064-.218 1.791-.465 2.427a4.902 4.902 0 01-1.153 1.772 4.902 4.902 0 01-1.772 1.153c-.636.247-1.363.416-2.427.465-1.067.048-1.407.06-4.123.06h-.08c-2.643 0-2.987-.012-4.043-.06-1.064-.049-1.791-.218-2.427-.465a4.902 4.902 0 01-1.772-1.153 4.902 4.902 0 01-1.153-1.772c-.247-.636-.416-1.363-.465-2.427-.047-1.024-.06-1.379-.06-3.808v-.63c0-2.43.013-2.784.06-3.808.049-1.064.218-1.791.465-2.427a4.902 4.902 0 011.153-1.772A4.902 4.902 0 015.45 2.525c.636-.247 1.363-.416 2.427-.465C8.901 2.013 9.256 2 11.685 2h.63zm-.081 1.802h-.468c-2.456 0-2.784.011-3.807.058-.975.045-1.504.207-1.857.344-.467.182-.8.398-1.15.748-.35.35-.566.683-.748 1.15-.137.353-.3.882-.344 1.857-.047 1.023-.058 1.351-.058 3.807v.468c0 2.456.011 2.784.058 3.807.045.975.207 1.504.344 1.857.182.466.399.8.748 1.15.35.35.683.566 1.15.748.353.137.882.3 1.857.344 1.054.048 1.37.058 4.041.058h.08c2.597 0 2.917-.01 3.96-.058.976-.045 1.505-.207 1.858-.344.466-.182.8-.398 1.15-.748.35-.35.566-.683.748-1.15.137-.353.3-.882.344-1.857.048-1.055.058-1.37.058-4.041v-.08c0-2.597-.01-2.917-.058-3.96-.045-.976-.207-1.505-.344-1.858a3.097 3.097 0 00-.748-1.15 3.098 3.098 0 00-1.15-.748c-.353-.137-.882-.3-1.857-.344-1.023-.047-1.351-.058-3.807-.058zM12 6.865a5.135 5.135 0 110 10.27 5.135 5.135 0 010-10.27zm0 1.802a3.333 3.333 0 100 6.666 3.333 3.333 0 000-6.666zm5.338-3.205a1.2 1.2 0 110 2.4 1.2 1.2 0 010-2.4z',
        'clip-rule': 'evenodd',
      }),
    ]),
})

export const DiscordIcon = defineComponent({
  render: () =>
    h('svg', { fill: 'currentColor', fillRule: 'evenodd', clipRule: 'evenodd', viewBox: '0 0 24 24' }, [
      h('path', {
        d:
          'M19.54 0c1.356 0 2.46 1.104 2.46 2.472v21.528l-2.58-2.28-1.452-1.344-1.536-1.428.636 2.22h-13.608c-1.356 0-2.46-1.104-2.46-2.472v-16.224c0-1.368 1.104-2.472 2.46-2.472h16.08zm-4.632 15.672c2.652-.084 3.672-1.824 3.672-1.824 0-3.864-1.728-6.996-1.728-6.996-1.728-1.296-3.372-1.26-3.372-1.26l-.168.192c2.04.624 2.988 1.524 2.988 1.524-1.248-.684-2.472-1.02-3.612-1.152-.864-.096-1.692-.072-2.424.024l-.204.024c-.42.036-1.44.192-2.724.756-.444.204-.708.348-.708.348s.996-.948 3.156-1.572l-.12-.144s-1.644-.036-3.372 1.26c0 0-1.728 3.132-1.728 6.996 0 0 1.008 1.74 3.66 1.824 0 0 .444-.54.804-.996-1.524-.456-2.1-1.416-2.1-1.416l.336.204.048.036.047.027.014.006.047.027c.3.168.6.3.876.408.492.192 1.08.384 1.764.516.9.168 1.956.228 3.108.012.564-.096 1.14-.264 1.74-.516.42-.156.888-.384 1.38-.708 0 0-.6.984-2.172 1.428.36.456.792.972.792.972zm-5.58-5.604c-.684 0-1.224.6-1.224 1.332 0 .732.552 1.332 1.224 1.332.684 0 1.224-.6 1.224-1.332.012-.732-.54-1.332-1.224-1.332zm4.38 0c-.684 0-1.224.6-1.224 1.332 0 .732.552 1.332 1.224 1.332.684 0 1.224-.6 1.224-1.332 0-.732-.54-1.332-1.224-1.332z',
      }),
    ]),
})

export const SnekIcon = defineComponent({
  render: () =>
    h('svg', { fill: 'currentColor', viewBox: '0 0 122.88 118.93' }, [
       h('path', {
        d:
          'M113.72,7.59l0.18,0.18c-1.32-0.78-3.16-1.05-5.46-1.05c-9.82,0-20.43,6.64-22.68,16.59 c-1.34,1.67-2.87,2.73-4.6,3.17c-4.99,1.28-7.72-2.09-11.51-4.37c-2.1-1.26-4.2-2.15-6.64-2.52c-1.77-0.27-3.63-0.28-5.6,0.04 c-21.9,3.52-21.23,24.5-10.27,38.58c3.2,4.11,6.97,8.13,10.29,11.96c3.41,3.94,6.81,7.58,7.54,11.24c1.01,5.06-2.95,8.86-7.64,7.83 c-4.2-0.92-9.77-4.16-15.75-7.9L20.02,68.89c-4-2.47-7.76-3.47-11.27-2.88c-6.52,1.1-9.87,6.76-8.41,13.13 c2.36,10.28,14.79,15.74,13.89,27.66c-0.34,4.57-3.06,8.61-8.08,12.12c1.37,0.06,2.92-0.5,4.65-1.68c1.81-1.23,3.25-2.48,4.35-3.83 c6.15-7.5,2.21-16.4-1.87-23.55c-0.88-1.55-1.67-3.2-2.14-5.26c-1.11-4.86,0.95-7.54,5.86-5.45c10.97,4.67,34.26,27.62,46.94,26.25 c8.83-0.96,15.76-8.59,17.75-16.94c2.51-10.55-4.68-17.7-10.56-25.98c-3.83-5.39-7.83-10.62-11.51-16.12 c-1.17-1.85-0.86-3.82,0.21-5.17c3.1-3.91,6.62,0.05,9.66,1.65c3.15,1.66,6.36,2.5,9.41,2.82c7.88,0.84,16.44-2.92,21.72-8.76 c9.82-2.94,15.36-13.03,15.73-23.09c0.07-1.93-0.21-3.37-0.83-4.43l0.03,0.03c1.9-1.82,4.71-3.03,7.33-3.77 c-1.68-0.52-3.35-0.81-4.99-0.65c0.32-1.65,0.22-3.31-0.22-4.99C116.59,2.69,115.09,5.61,113.72,7.59L113.72,7.59z M111.53,20.13 c-0.88-0.88-2.32-0.88-3.2,0c-0.89,0.88-0.89,2.32,0,3.2c0.88,0.88,2.32,0.88,3.2,0C112.41,22.45,112.41,21.01,111.53,20.13 L111.53,20.13z M102.97,11.57c-0.89-0.89-2.32-0.89-3.2,0c-0.88,0.88-0.88,2.32,0,3.2c0.88,0.89,2.32,0.89,3.2,0 C103.86,13.89,103.86,12.46,102.97,11.57L102.97,11.57z',
      }),
    ]),
})
