import gql from 'graphql-tag'

export const walletsByTalkId = gql`query walletByTalkId ($id: String!) {
  wallets(where: { user: { talkPublicKey: { _eq: $id } } }) {
    id
    chainName
    address
  }
}`

export const listWalletItems = gql`query listWalletItems ($id: uuid!) {
  walletItems(id: $id) {
    id
    name
    dropName
    dropSlug
    thumbnailUrl
    rarityGrade
    rarityLabel
    rarityRank
  }
}`
