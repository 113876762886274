import moment from 'moment'
import VueApolloComponents from '@vue/apollo-components'
import VueCountdown from '@chenfengyuan/vue-countdown'
import Notifications from '@kyvg/vue3-notification'
import VTooltipPlugin from 'v-tooltip'
import { createApp, provide, h } from 'vue'
import { DefaultApolloClient } from '@vue/apollo-composable'
import { createApolloProvider } from '@vue/apollo-option'
import { apolloClient } from '@/services/apollo'

import App from './App.vue'

// Styles
import './assets/css/index.css'

// Routing
import router from './router'

// Vuex
import store from './store'

const apolloProvider = new createApolloProvider({
  defaultClient: apolloClient,
})

const app = createApp({
  setup () {
    provide(DefaultApolloClient, apolloClient)
  },
  render: () => h(App),
})
app.use(router)
app.use(store)
app.use(apolloProvider)
app.use(VueApolloComponents)
app.use(Notifications)
app.use(VTooltipPlugin)
app.component(VueCountdown.name, VueCountdown)
app.mount('#app')

// https://stackoverflow.com/questions/70084513/require-is-not-defined-when-importing-project-serum-anchor
window.JS_SHA256_NO_NODE_JS = true

const usdFormatter = new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' })
const numberFormatter = new Intl.NumberFormat()

app.config.globalProperties = {
  ...app.config.globalProperties,
  $filters: {
    // Convert wei to eth and round to decimal places
    currencyWei: (wei, decimal = 5) => {
      const eth = wei / Math.pow(10, 18)
      return Math.round((eth + Number.EPSILON) * Math.pow(10, decimal)) / Math.pow(10, decimal)
    },
    // Round and format eth
    currencyEth: (eth, decimal = 5) => {
      return Math.round(eth * Math.pow(10, decimal)) / Math.pow(10, decimal)
    },
    // Round and format USD
    currencyUsd: usd => usdFormatter.format(usd),
    addressFmt: address => {
      const match = address.match(/^([a-zA-Z0-9]{6})[a-zA-Z0-9]+([a-zA-Z0-9]{6})$/);
      if (!match) return address;
      return `${match[1]}…${match[2]}`;
    },
    numberFmt: value => numberFormatter.format(value),
    moment,
  }
}
