import RoofService from '@/services/roof.service'

export default {
  namespaced: true,
  state: {
    rates: {
      data: null,
      updated: null,
    },
  },
  getters: {
    // Update rates data at most once every minute
    ratesValid: ({ rates }) => rates.updated && (rates.updated > Date.now() - 1000 * 60 * 60),
    rates: ({ rates }) => rates.data,
  },
  actions: {
    async loadRates({ commit, dispatch, getters }) {
      // If recently updated return cached data
      if (getters.ratesValid) return getters.rates
      // Return real data
      const { data, promise } = RoofService.useFiatRates()
      await promise
      commit('SET_RATES', data)
      return data
    },

  },
  mutations: {
    SET_RATES(state, data) {
      state.rates.data = data
      state.rates.updated = Date.now()
    }
  }
}