<template>
  <component v-if="type" :is="type" :class="[color, 'h-6 w-6 mr-3']" aria-hidden="true" />
</template>

<script setup>
import { computed } from 'vue'
import { CheckCircleIcon, XCircleIcon, ExclamationCircleIcon, InformationCircleIcon } from '@heroicons/vue/outline'

const props = defineProps({ item: Object })

const type = computed(() => {
  const typeMap = {
    'info': InformationCircleIcon,
    'success': CheckCircleIcon,
    'warn': ExclamationCircleIcon,
    'error': XCircleIcon
  }
  return typeMap[props.item.type]
})

const color = computed(() => {
  const colorMap = {
    'info': 'text-blue-400',
    'success': 'text-green-400',
    'warn': 'text-yellow-400',
    'error': 'text-red-400',
  }
  return colorMap[props.item.type]
})
</script>