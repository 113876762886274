import { toRefs, reactive } from 'vue'
import { createJam } from 'jam-core'

let state, api

export function useJam() {
  if (!state) {
    const [_state, _api] = createJam({
      jamConfig: {
        domain: 'talk.diamondhandshotel.com',
        sfu: true,
      },
    })

    api = _api
    state = reactive({ ..._state })
    state.speaking = [...state.speaking]

    api.onState((key, value) => {
      if (key === 'speaking') {
        state[key] = [...value]
        console.log(`speaking = ${[...value].join(', ')}`)
      } else if (key === 'room') {
        for (const [_key, _value] of Object.entries(value)) {
          console.log(`room.${_key} = ${_value}`)
          state.room[_key] = _value
        }
      } else if (key === 'reactions') {
        for (const [_key, _value] of Object.entries(value)) {
          console.log(`reactions.${_key} = ${_value}`)
          state.reactions[_key] = [ ..._value ]
        }
      } else if (key === 'identities') {
        for (const [_key, _value] of Object.entries(value)) {
          console.log(`identities.${_key} = ${_value}`)
          state.identities[_key] = { ..._value }
        }
      } else if (key === 'peers') {
        console.log(`peers = ${value.join(', ')}`)
        state.peers = [ ...value ]
      } else if (typeof value === 'object' && value && !['myAudio'].includes(key)) {
        console.log(`${key} = ${JSON.stringify(value, null, 2)}`)
        state[key] = { ...value }
      } else {
        state[key] = value
      }
    })
  }

  return {
    state,
    api
  }
}