<template>
  <notifications width="100%" class="notif-root w-full fixed inset-0 flex items-end px-4 py-6 pointer-events-none sm:p-6 sm:items-start">
    <template #body="{ item, close }">
      <DNotification :item="item" :close="close" class="mb-3" />
    </template>
  </notifications>
  <router-view v-slot="{ Component, route }">
    <transition v-bind="classes">
      <component :is="Component" :key="route.path"></component>
    </transition>
  </router-view>
</template>

<script setup>
import { useRoute } from 'vue-router'
import { computed } from 'vue'
import { DNotification } from '@ui'

const route = useRoute()
const classes = computed(() => {
  if (route.path.startsWith('/roof')) return {}
  return {
    'enter-active-class': 'transition-all duration-400 ease-out',
    'leave-active-class': 'transition-all duration-400 ease-in',
    'enter-from-class': 'opacity-0',
    'enter-to-class': 'opacity-100',
    'leave-from-class': 'opacity-100',
    'leave-to-class': 'opacity-0',
    'mode': 'out-in',
  }
})
</script>

<style>
.notif-root > span {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.notif-root > span > .vue-notification-wrapper {
  width: 100%;
  max-width: 24rem;
}

@media (min-width: 640px) {
  .notif-root > span {
    align-items: flex-end;
  }
}
</style>