const colors = require('tailwindcss/colors')

module.exports = {
  purge: {
    content: ['./public/**/*.html', './src/**/*.vue'],
    safelist: [
      'bg-gray-600',
      'bg-purple-100',
      'bg-purple-600',
      'bg-blue-50',
      'bg-blue-500',
      'bg-blue-600',
      'bg-green-50',
      'bg-green-500',
      'bg-green-600',
      'text-purple-700',
      'text-blue-700',
      'text-blue-900',
      'text-green-700',
      'text-green-900',
      'border-green-500',
      'border-green-200',
      'border-blue-500',
      'border-blue-200',
      'ring-green-500',
      'ring-blue-500',
      'hover:bg-purple-200',
      'hover:bg-purple-700',
      'focus:ring-purple-500',
      'focus:ring-blue-500',
      'focus:ring-green-500',
      'focus:border-blue-500',
      'focus:border-green-500',
    ]
  },
  darkMode: false, // or 'media' or 'class'
  theme: {
    extend: {
      typography: {
        DEFAULT: {
          css: {
            strong: null,
            h2: null,
          },
        },
      },
      colors: {
        sky: colors.sky,
        teal: colors.teal,
        cyan: colors.cyan,
        rose: colors.rose,
        'litepie-primary': colors.purple,
      },
      transitionDuration: {
        '400': '400ms',
      },
      height: {
        'screen/2': '50vh',
      },
      objectPosition: {
        'right-75': '75% 50%;',
        'top-25': '50% 25%;',
      },
      scale: {
        '-1': '-1',
      },
    },
  },
  variants: {
    extend: {
      opacity: ['disabled'],
      cursor: ['disabled'],
      textOpacity: ['disabled'],
      textColor: ['disabled']
    }
  },
  plugins: [
    require('@tailwindcss/aspect-ratio'),
    require('@tailwindcss/line-clamp'),
    require('@tailwindcss/forms'),
    require('@tailwindcss/typography'),
  ],
}