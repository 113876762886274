<template>
  <div v-if="!ready" class="flex-1 flex flex-col">
    <div class="bg-gradient-to-r from-pink-600 to-purple-600 h-2"></div>
    <div class="px-6 pt-3 pb-4 bg-white shadow">
      <div class="flex items-start justify-between">
        <div class="text-xl font-bold text-gray-900 sm:text-2xl">
          The Bar
        </div>
        <div class="ml-3 h-7 flex items-center">
          <button type="button" class="rounded-md text-gray-400 hover:text-gray-500 focus:ring-2 focus:ring-purple-600" @click="open = false">
            <span class="sr-only">Close panel</span>
            <XIcon class="h-6 w-6" aria-hidden="true" />
          </button>
        </div>
      </div>
    </div>
    <div class="mt-4 flex-1 px-4 sm:px-6 overflow-y-auto">
      <div class="bg-white shadow rounded-lg overflow-hidden">
        <div class="h-32 overflow-hidden">
          <div class="bg-gray-200 animate-pulse h-full"></div>
        </div>
        <div class="flex z-10 justify-center px-5 -mt-20">
          <div class="z-10 h-32 w-32 bg-gray-300 p-2 rounded-full"></div>
        </div>
        <div class="mt-2">
          <div class="px-14">
            <div class="bg-gray-200 h-8 w-36 rounded mx-auto"></div>
            <div class="mt-2 bg-gray-200 h-4 w-32 rounded mx-auto"></div>
          </div>
          <hr class="mt-6" />
          <div class="flex divide-x divide-gray-200">
            <div class="flex-1 flex bg-gray-50 text-gray-700 font-semibold p-6"></div>
            <div class="flex-1 flex bg-gray-50 text-gray-700 font-semibold p-6"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <slot v-else></slot>
</template>

<script setup>
import { provide, ref, computed } from 'vue'
import { useStore } from 'vuex'
import { XIcon } from '@heroicons/vue/outline'
import { useQuery, useResult } from '@vue/apollo-composable'
import { importDefaultIdentity } from 'jam-core'
import { talkIdentity } from '@/gql/auth'

const store = useStore()
const ready = ref(false)
const { result, onResult } = useQuery(talkIdentity, {
  fetchPolicy: 'no-cache'
})

const identity = useResult(result, null, ({ identity }) => ({
  publicKey: identity.talkPublicKey,
  secretKey: identity.talkSecretKey,
  info: {
    name: identity.displayName,
    avatar: identity.avatarImg
  }
}))

if (identity) {
  ready.value = true
}

const open = computed({
  get: () => store.getters['talk/open'],
  set: (val) => store.commit('talk/SET_OPEN', val)
})

onResult(async () => {
  if (identity.value) {
    await importDefaultIdentity(identity.value)
  }
  ready.value = true
})
</script>