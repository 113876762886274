<template>
  <Menu as="div" class="relative">
    <MenuButton>
      <slot></slot>
    </MenuButton>

    <transition enter-active-class="transition ease-out duration-75" enter-from-class="transform opacity-0 scale-95" enter-to-class="transform opacity-100 scale-100" leave-active-class="transition ease-in duration-75" leave-from-class="transform opacity-100 scale-100" leave-to-class="transform opacity-0 scale-95">
      <MenuItems class="origin-top-right absolute left-1 mt-2 w-44 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 z-10 divide-y divide-gray-100 focus:outline-none">
        <ActionsMe v-if="isMe" />
        <ActionsPublic :info="info" :peer-id="peerId" />
        <ActionsModerator v-if="iAmModerator" :peer-id="peerId" />
      </MenuItems>
    </transition>
  </Menu>
</template>

<script setup>
import { computed, toRefs } from 'vue'
import { Menu, MenuButton, MenuItems } from '@headlessui/vue'
import { useJam } from '../lib/use-jam'
import ActionsMe from './ActionsMe.vue'
import ActionsPublic from './ActionsPublic.vue'
import ActionsModerator from './ActionsModerator.vue'

const props = defineProps(['peerId', 'info'])
const { state } = useJam()
const { iAmModerator, myId } = toRefs(state)
const { peerId } = toRefs(props)

const isMe = computed(() => peerId.value === myId.value)
</script>