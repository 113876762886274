import { ref } from 'vue'
import store from '@/store'

import RestService from './rest.service'

class RoofService extends RestService {
  constructor() {
    super()
  }

  useWalletTokens() {
    return this._useRestQuery({
      defaultValue: [],
    }, query => query('/wallet/tokens', null, 'GET'))
  }

  useDropWallet(slug) {
    return this._useRestQuery({
      defaultValue: [],
    }, query => query(`/wallet/drops/${slug}/tokens`, null, 'GET'))
  }

  useRoomWallet(slug) {
    return this._useRestQuery({
      defaultValue: []
    }, query => query(`/wallet/rooms/${slug}/tokens`, null, 'GET'))
  }

  useToken(id) {
    return this._useRestQuery({
      defaultValue: null,
    }, query => query(`/tokens/${id}`, null, 'GET'))
  }

  useTokenOwner(id) {
    return this._useRestQuery({
      defaultValue: []
    }, query => query(`/tokens/${id}/owner`, null, 'GET'))
  }

  useTokenOrders(id) {
    return this._useRestQuery({
      defaultValue: { buy: [], sell: [] }
    }, query => query(`/tokens/${id}/orders`, null, 'GET'))
  }

  useRooms() {
    return this._useRestQuery({
      defaultValue: [],
    }, query => query('/rooms', null, 'GET'))
  }

  useRoom(slug) {
    return this._useRestQuery({
      defaultValue: null
    }, query => query(`/rooms/${slug}`, null, 'GET'))
  }

  useDrop(slug) {
    return this._useRestQuery({
      defaultValue: null
    }, query => query(`/drops/${slug}`, null, 'GET'))
  }

  useDropTokens(slug) {
    return this._useRestQuery({
      defaultValue: []
    }, query => query(`/drops/${slug}/tokens`, null, 'GET'))
  }

  useWalletProfile(address) {
    return this._useRestQuery({
      defaultValue: []
    }, query => query(`/wallets/${address}/profile`, null, 'GET'))
  }

  useFiatRates() {
    return this._useCache({
      key: 'FIAT_RATES',
      ttl: 60
    }, this._useRestQuery({
      defaultValue: { base: null, rates: {} },
      immediate: false,
    }, query => query(`/data/rates`, null, 'GET')))
  }
}

export default new RoofService()