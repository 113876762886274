import { ethers } from 'ethers'
import { web3AuthNonce, web3AuthVerify } from '@/gql/auth'
import { apolloClient } from './apollo'

class AuthService {
  constructor() {
    this._connected = []
  }

  async _getNonce(address) {
    const { data: { web3AuthNonce: { nonce } } } = await apolloClient.mutate({
      mutation: web3AuthNonce,
      variables: { address },
    });

    return nonce
  }

  async _verifySignature(chainName, address, nonce, signature) {
    const { data: { web3AuthVerify: { token, user } } } = await apolloClient.mutate({
      mutation: web3AuthVerify,
      variables: { chainName, address, nonce, signature },
    });

    return { token, user }
  }
  
  async connectMetamask() {
    const chainName = 'ETHEREUM'
    const provider = new ethers.providers.Web3Provider(window.ethereum)
    await provider.send('eth_requestAccounts', [])

    const signer = provider.getSigner()
    const address = await signer.getAddress()

    // Fetch and sign nonce
    const nonce = await this._getNonce(address)
    const signature = await signer.signMessage(nonce)

    // Verify signed nonce
    const auth = await this._verifySignature(chainName, address, nonce, signature)

    const account = { chainName, address, ...auth }
    this._connected.push(account)

    return account
  }

  async connectPhantom() {
    const chainName = 'SOLANA'
    const res = await window.solana.connect()
    const address = res.publicKey.toString()

    const nonce = await this._getNonce(address)
    const encodedNonce = new TextEncoder().encode(nonce)
    const { signature } = await window.solana.signMessage(encodedNonce, 'utf8')

    const auth = await this._verifySignature(chainName, address, nonce, signature.toString('hex'))

    const account = { chainName, address, ...auth }
    this._connected.push(account)

    return account
  }
}

export default new AuthService()