<template>
  <div class="py-1">
    <template v-if="!stageOnly">
      <MenuItem v-if="isSpeaker" v-slot="{ active }">
        <button @click="removeSpeaker(roomId, peerId)" :class="[active ? 'bg-gray-100 text-gray-900' : 'text-gray-700', 'group flex items-center px-4 py-2 text-xs block w-full']">
          <ArrowCircleDownIcon class="mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500" aria-hidden="true" />
          Move to Audience
        </button>
      </MenuItem>
      <MenuItem v-else v-slot="{ active }">
        <button @click="addSpeaker(roomId, peerId)" :class="[active ? 'bg-gray-100 text-gray-900' : 'text-gray-700', 'group flex items-center px-4 py-2 text-xs block w-full']">
          <ArrowCircleUpIcon class="mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500" aria-hidden="true" />
          Invite to Stage
        </button>
      </MenuItem>
    </template>


    <MenuItem v-if="isModerator && !isMe" v-slot="{ active }">
      <button @click="removeModerator(roomId, peerId)" :class="[active ? 'bg-gray-100 text-gray-900' : 'text-gray-700', 'group flex items-center px-4 py-2 text-xs block w-full']">
        <XCircleIcon class="mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500" aria-hidden="true" />
        Demote Moderator
      </button>
    </MenuItem>
    <MenuItem v-else-if="!isMe" v-slot="{ active }">
      <button @click="addModerator(roomId, peerId)" :class="[active ? 'bg-gray-100 text-gray-900' : 'text-gray-700', 'group flex items-center px-4 py-2 text-xs block w-full']">
        <BadgeCheckIcon class="mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500" aria-hidden="true" />
        Make Moderator
      </button>
    </MenuItem>
  </div>
</template>

<script setup>
import { computed, toRefs } from 'vue'
import { MenuItem } from '@headlessui/vue'
import { BadgeCheckIcon, ArrowCircleDownIcon, ArrowCircleUpIcon, XCircleIcon } from '@heroicons/vue/solid'
import { useJam } from '../lib/use-jam'

const props = defineProps(['peerId'])
const { peerId } = toRefs(props)
const { state, api } = useJam()
const {
  removeSpeaker,
  addSpeaker,
  addModerator,
  removeModerator,
} = api

const { myId, roomId } = toRefs(state)
const { speakers, moderators, stageOnly } = toRefs(state.room)
const isSpeaker = computed(() => stageOnly?.value || speakers?.value.includes(peerId.value))
const isModerator = computed(() => moderators.value.includes(peerId.value))
const isMe = computed(() => peerId.value === myId.value)
</script>