<template>
  <div v-if="room.buttonText && room.buttonURI" class="-mt-2 mb-3">
    <a :href="room.buttonURI" target="_blank" class="flex items-center justify-center px-4 py-3 text-sm font-medium rounded-md shadow-sm text-white bg-gradient-to-bl from-purple-600 to-pink-600 hover:from-purple-700 hover:to-pink-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-pink-500">
      {{ room.buttonText }} &rarr;
    </a>
  </div>
</template>

<script setup>
import { toRefs } from 'vue'
import { useJam } from '../lib/use-jam'

const { state } = useJam()
const { room } = toRefs(state)
</script>