<!-- This example requires Tailwind CSS v2.0+ -->
<template>
  <div>
    <TransitionRoot as="template" :show="open">
      <Dialog as="div" class="z-10 fixed inset-0 overflow-hidden" @close="open = false">
        <div class="absolute inset-0 overflow-hidden">
          <TransitionChild as="template" enter="ease-in-out duration-500" enter-from="opacity-0" enter-to="opacity-100" leave="ease-in-out duration-500" leave-from="opacity-100" leave-to="opacity-0">
            <DialogOverlay class="absolute inset-0 bg-gray-900 bg-opacity-60 transition-opacity" />
          </TransitionChild>

          <div class="fixed inset-y-0 right-0 max-w-full flex sm:pl-16">
            <TransitionChild as="template" enter="transform transition ease-in-out duration-500 sm:duration-700" enter-from="translate-x-full" enter-to="translate-x-0" leave="transform transition ease-in-out duration-500 sm:duration-700" leave-from="translate-x-0" leave-to="translate-x-full">
              <div class="w-screen max-w-md">
                <div class="h-full flex flex-col bg-white shadow-lg overflow-y-scroll">
                  <IdentityProvider>
                    <JamRouter />
                  </IdentityProvider>
                </div>
              </div>
            </TransitionChild>
          </div>

          
          <EditRoom />
          <EditIdentity />
          <UserWallet />
        </div>
      </Dialog>
    </TransitionRoot>
  </div>
</template>

<script setup>
import { computed } from 'vue'
import {
  Dialog,
  DialogOverlay,
  Menu,
  MenuButton,
  MenuItem,
  MenuItems,
  TransitionChild,
  TransitionRoot,
} from '@headlessui/vue'
import { useStore } from 'vuex'

import IdentityProvider from './auth/IdentityProvider.vue'
import JamRouter from './auth/JamRouter.vue'
import UserWallet from './UserWallet.vue'
import EditRoom from './EditRoom.vue'
import EditIdentity from './EditIdentity.vue'

const store = useStore()
const open = computed({
  get: () => store.getters['talk/open'],
  set: (val) => store.commit('talk/SET_OPEN', val)
})
</script>