import gql from 'graphql-tag'

export const listRooms = gql`query listTalkRooms ($where: talkrooms_bool_exp, $order: [talkrooms_order_by!], $limit: Int, $offset: Int) {
  rooms: talkrooms(where: $where, order_by: $order, limit: $limit, offset: $offset) {
    id
    name
    description
    color
    stageOnly
    autoJoin
    casual
    drop {
      id
      name
      slug
      avatarImg
      coverImg
    }
    stats {
      members
    }
    auth {
      key
    }
  }
}`
