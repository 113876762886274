<!-- This example requires Tailwind CSS v2.0+ -->
<template>
  <TransitionRoot as="template" :show="open">
    <Dialog as="div" class="z-10 fixed inset-0 overflow-hidden" @close="open = false">
      <div class="absolute inset-0 overflow-hidden">
        <TransitionChild as="template" enter="ease-in-out duration-500" enter-from="opacity-0" enter-to="opacity-100" leave="ease-in-out duration-500" leave-from="opacity-100" leave-to="opacity-0">
          <DialogOverlay class="absolute inset-0 bg-gray-900 bg-opacity-60 transition-opacity" />
        </TransitionChild>

        <div class="fixed inset-y-0 right-0 max-w-full flex sm:pl-16">
          <TransitionChild as="template" enter="transform transition ease-in-out duration-500 sm:duration-700" enter-from="translate-x-full" enter-to="translate-x-0" leave="transform transition ease-in-out duration-500 sm:duration-700" leave-from="translate-x-0" leave-to="translate-x-full">
            <div class="w-screen max-w-md">
              <div class="h-full flex flex-col bg-white shadow-lg overflow-y-scroll">
                <div class="flex-1 flex flex-col overflow-y-auto">
                  <UserWalletPicker>
                    <template #title-append>
                      <button type="button" class="rounded-md text-gray-400 hover:text-gray-500 focus:ring-2 focus:ring-purple-600" @click="open = false">
                        <span class="sr-only">Close panel</span>
                        <XIcon class="h-6 w-6" aria-hidden="true" />
                      </button>
                    </template>
                    <template v-slot:default="{ wallet }">
                      <UserWalletContents :wallet="wallet" />
                    </template>
                  </UserWalletPicker>
                </div>
              </div>
            </div>
          </TransitionChild>
        </div>
      </div>
    </Dialog>
  </TransitionRoot>
</template>

<script setup>
import { computed, watch } from 'vue'
import { XIcon } from '@heroicons/vue/outline'
import {
  Dialog,
  DialogOverlay,
  DialogTitle,
  TransitionChild,
  TransitionRoot,
} from '@headlessui/vue'
import { useStore } from 'vuex'
import UserWalletPicker from './ui/UserWalletPicker.vue'
import UserWalletContents from './ui/UserWalletContents.vue'

const store = useStore()

const open = computed({
  get: () => store.getters['talk/walletModalOpen'],
  set: (val) => store.commit('talk/MODAL_WALLET', val)
})
</script>