<template>
  <div class="grid grid-cols-3 gap-x-2">
    <template v-if="iAmSpeaker">
      <!-- microphone controls (user is speaker) -->
      <button @click="toggleMute" type="button" :class="[micDisabled ? 'border-transparent text-purple-700 bg-purple-100 hover:bg-purple-200' : 'border-gray-300 text-gray-700 bg-white hover:bg-gray-50', 'inline-flex items-center justify-center px-4 py-2 sm:py-3 border shadow-sm text-base font-medium rounded-md focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-purple-500']">
        <component :is="micDisabled ? MicrophoneOffIcon : MicrophoneIcon" class="w-5 h-5 -ml-0.5 mr-1.5" />
        {{ micDisabled ? 'Unmute' : 'Mute' }}
      </button>
    </template>
    <template v-else>
      <!-- hand controls (user is audience) -->
      <button @click="toggleHand" type="button" :class="[handRaised ? 'border-transparent text-purple-700 bg-purple-100 hover:bg-purple-200' : 'border-gray-300 text-gray-700 bg-white hover:bg-gray-50', 'inline-flex items-center justify-center px-4 py-2 sm:py-3 border shadow-sm text-base font-medium rounded-md focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-purple-500']">
        <HandIcon class="w-5 h-5 -ml-0.5 mr-1.5" />
        {{ handRaised ? 'Cancel' : 'Raise' }}
      </button>
    </template>

    <Menu as="div" class="relative inline-block text-left">
      <div>
        <MenuButton class="w-full flex items-center justify-center px-4 py-3 sm:py-4 border border-gray-300 shadow-sm text-base font-medium rounded-md text-gray-700 bg-white space-x-2 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-purple-500">
          <HeartIcon class="w-5 h-5" />
          <ThumbUpIcon class="w-5 h-5" />
        </MenuButton>
      </div>

      <transition enter-active-class="transition ease-out duration-100" enter-from-class="transform opacity-0 scale-95" enter-to-class="transform opacity-100 scale-100" leave-active-class="transition ease-in duration-75" leave-from-class="transform opacity-100 scale-100" leave-to-class="transform opacity-0 scale-95">
        <MenuItems class="z-10 origin-top-right absolute right-0 mt-2 w-44 sm:w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none grid grid-cols-3">
          <MenuItem v-for="emoji in reactionEmojis" :key="emoji" v-slot="{ active }">
            <button @click="sendReaction(emoji)" :class="[active ? 'bg-gray-100 text-gray-900' : 'text-gray-700', 'flex items-center justify-center text-3xl sm:text-4xl py-3']">
              {{ emoji }}
            </button>
          </MenuItem>
        </MenuItems>
      </transition>
    </Menu>

    <button @click="leaveRoom(roomId)" class="inline-flex items-center justify-center px-4 py-2 sm:py-3 border border-transparent text-base font-medium rounded-md text-pink-700 bg-gray-100 hover:bg-gray-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-pink-500">
      <span class="-ml-0.5 mr-1.5 text-lg">✌️</span>Leave
    </button>
  </div>
</template>

<script setup>
import { toRefs, computed } from 'vue'
import { Menu, MenuButton, MenuItem, MenuItems } from '@headlessui/vue'
import { XCircleIcon } from '@heroicons/vue/solid'
import { HandIcon, HeartIcon, ThumbUpIcon, MicrophoneIcon } from '@heroicons/vue/outline'
import { MicrophoneOffIcon } from '@ui'
import { useJam } from '../lib/use-jam'
import { reactionEmojis } from '../lib/config'

const props = defineProps(['roomId', 'room', 'noLeave'])
const { roomId, room, noLeave } = toRefs(props)
const { state, api } = useJam()
const { sendReaction, leaveRoom, retryMic, setProps } = api

const {
  iAmSpeaker,
  myAudio,
  micMuted,
  handRaised
} = toRefs(state)

const micOn = computed(() => myAudio.value?.active)
const micDisabled = computed(() => !micOn.value || micMuted.value)
const toggleHand = () => setProps('handRaised', !handRaised.value)
const toggleMute = () => {
  if (micOn.value) {
    setProps('micMuted', !micMuted.value)
  } else {
    retryMic()
  }
}
</script>