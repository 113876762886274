import { getMainDefinition } from '@apollo/client/utilities'
import { WebSocketLink } from '@apollo/client/link/ws'
import { setContext } from '@apollo/client/link/context'
import { ApolloClient, ApolloLink, InMemoryCache, HttpLink, split } from '@apollo/client/core'

import store from '@/store'
const cache = new InMemoryCache()
const httpLink = new HttpLink({
  uri: process.env.VUE_APP_GRAPHQL_HTTP,
})
const wsLink = new WebSocketLink({
  uri: process.env.VUE_APP_GRAPHQL_WS,
  options: {
    reconnect: true,
  },
})

const authLink = setContext(req => new Promise((r) => {
  const { variables } = req
  const token = store.getters['auth/token']
  const AUTH_HEADERS = {
    'x-hasura-role': 'anonymous',
  };

  if (token) {
    AUTH_HEADERS['x-hasura-role'] = 'user'
    AUTH_HEADERS['authorization'] = `Bearer ${token}`
  }

  return r({ headers: AUTH_HEADERS });
}))

// The split function takes three parameters:
//
// * A function that's called for each operation to execute
// * The Link to use for an operation if the function returns a "truthy" value
// * The Link to use for an operation if the function returns a "falsy" value
const splitLink = split(
  ({ query }) => {
    const definition = getMainDefinition(query);
    return (
      definition.kind === 'OperationDefinition' &&
      definition.operation === 'subscription'
    );
  },
  wsLink,
  httpLink,
)

// Create the apollo client
export const apolloClient = new ApolloClient({
  link: ApolloLink.from([
    authLink,
    splitLink,
  ]),
  cache,
})