<template>
  <component :is="type" :item="item" :close="close" />
</template>

<script setup>
import { computed } from 'vue'
import Simple from './DNotifSimple.vue'
import Condensed from './DNotifCondensed.vue'
import Avatar from './DNotifAvatar.vue'

const props = defineProps({ item: Object, close: Function })
const type = computed(() => {
  // If has avatar image
  if (typeof props.item.data.avatarImg !== 'undefined') return Avatar

  // If has no title
  if (!props.item.title) return Condensed
  
  // Default
  return Simple
})
</script>