import { createClient } from '@supabase/supabase-js'

const supabaseUrl = process.env.VUE_APP_SUPABASE_URL
const supabaseAnonKey = process.env.VUE_APP_SUPABASE_ANON_KEY

export const supabase = createClient(supabaseUrl, supabaseAnonKey)

supabase.auth.onAuthStateChange((event, session) => {
  console.log(event, session)
})

export const newsletterSignup = async (email, campaign) => {
  const { data, error } = await supabase
    .from('mailinglist')
    .insert({ email, campaign, domain: 'diamondhandshotel.com' }, { returning: 'minimal' })

  if (error) return [false, 'Please try again later']
  return [true]
}