<template>
  <button v-if="inRoom" @click="openBar" class="text-gray-700 hover:text-gray-900 hover:bg-gray-50 group flex items-center px-2 py-2 text-sm font-medium rounded-md w-full">
    <MicrophoneIcon v-if="iAmSpeaker" :class="[isSpeaking ? 'text-red-700 animate-pulse' : 'text-red-300 group-hover:text-red-400', 'mr-3 flex-shrink-0 h56 w-5']" />
    <VolumeUpIconSolid v-else class="text-purple-600 roup-hover:text-purple-700 mr-3 animate-pulse flex-shrink-0 h56 w-5" />
    {{ room.name }}
    <span v-if="peers.length" class="ml-auto mr-0 inline-flex items-center px-2 py-0.5 rounded text-xs font-semibold tracking-wide uppercase bg-purple-100 text-purple-800 flex items-center">
      <UserIcon class="inline w-3 h-3 -ml-0.5 mr-1" />
      <span>{{ peers.length + 1 }}</span>
    </span>
  </button>
  <button v-else @click="openBar" class="text-gray-700 hover:text-gray-900 hover:bg-gray-50 group flex items-center px-2 py-2 text-sm font-medium rounded-md w-full">
    <VolumeUpIcon class="text-gray-400 group-hover:text-gray-500 mr-3 flex-shrink-0 h-6 w-6" />
    The Bar
    <span class="ml-auto mr-0 inline-flex items-center px-2 py-0.5 rounded text-xs font-semibold tracking-wide uppercase bg-purple-100 text-purple-800">
      New!
    </span>
  </button>
</template>

<script setup>
import { computed, toRefs } from 'vue'
import { useStore } from 'vuex'
import { VolumeUpIcon } from '@heroicons/vue/outline'
import { VolumeUpIcon as VolumeUpIconSolid, MicrophoneIcon, UserIcon} from '@heroicons/vue/solid'
import { useJam } from './lib/use-jam'

const props = defineProps(['sidebarOpen'])
const emit = defineEmits(['update:sidebarOpen'])

const { state } = useJam()
const { iAmSpeaker, inRoom, room, peers, speaking, myId } = toRefs(state)
const isSpeaking = computed(() => speaking.value.includes(myId.value))

const store = useStore()
const talkOpen = computed({
  get: () => store.getters['talk/open'],
  set: (val) => store.commit('talk/SET_OPEN', val)
})

const openBar = () => {
  emit('update:sidebarOpen', false)
  talkOpen.value = true
}
</script>