<template>
  <EnterRoom
    v-if="!iAmModerator && room.closed"
    v-bind="room"
    :room-id="roomId" />

  <EnterRoom
    v-else-if="!hasEnteredRoom"
    v-bind="room"
    :room-id="roomId" />

  <Container v-else class="flex flex-col">
    <div class="flex-1 flex flex-col overflow-y-auto pt-0.5">
      <WebViewTip />
      <RoomClosedTip v-if="room.closed" />
      <PermissionsTip />

      <!-- stage -->
      <div class="flex-shrink-0 p-4 sm:p-6">
        <CtaBtn />

        <ol v-if="iAmSpeaker || stagePeers.length > 0" class="flex flex-wrap">
          <!-- todo: needs onClick => setEditSelf(true) -->
          <StageAvatar
            v-if="iAmSpeaker"
            :key="myPeerId"
            :peer-id="myPeerId"
            :speaking="speaking"
            :moderators="room.moderators"
            :reactions="reactions"
            :room="room"
            :can-speak="!hasMicFailed"
            :peer-state="myPeerState"
            :info="myInfo" />

          <!-- todo: needs onClick => iAmModerator ? () => setEditRole(peerId) : undefined -->
          <StageAvatar
            v-for="peerId in stagePeers"
            :key="peerId"
            :speaking="speaking"
            :moderators="room.moderators"
            :reactions="reactions"
            :room="room"
            :can-speak="true"
            :peer-id="peerId"
            :peer-state="peerState[peerId]"
            :info="identities[peerId]" />
        </ol>
        <div v-else class="bg-gray-100 overflow-hidden rounded-lg">
          <div class="px-4 py-5 sm:p-6 text-center">
            <VolumeOffIcon class="mx-auto h-8 w-8 text-gray-400" />
            <span class="mt-2 block text-sm font-medium text-gray-900">
              No one is on stage
            </span>
          </div>
        </div>

        <div class="mt-8">
          <Navigation
            :room-id="roomId"
            :room="room"
            :no-leave="noLeave" />
        </div>
      </div>

      <div>
        <div class="border-b border-gray-200">
          <div class="px-4 sm:px-6">
            <nav class="-mb-px flex space-x-8" aria-label="Tabs">
              <div class="border-purple-500 text-purple-600 whitespace-nowrap py-4 px-1 border-b-2 font-medium text-sm">
                Audience
              </div>
            </nav>
          </div>
        </div>
      </div>

      <!-- audience -->
      <div v-if="!room.stageOnly" class="flex-1 overflow-y-auto p-4 sm:p-6">
        <ol v-if="!iAmSpeaker || audiencePeers.length > 0" class="flex flex-wrap">
          <!-- todo: needs onClick => setEditSelf(true) -->
          <AudienceAvatar
            v-if="!iAmSpeaker"
            :room="room"
            :reactions="reactions"
            :moderators="room.moderators"
            :peer-id="myPeerId"
            :peer-state="peerState[myPeerId]"
            :info="myInfo"
            :moderator="iAmModerator" />

          <!-- todo: needs onClick => iAmModerator ? () => setEditRole(peerId) : undefined -->
          <AudienceAvatar
            v-for="peerId in audiencePeers"
            :key="peerId"
            :room="room"
            :reactions="reactions"
            :moderators="room.moderators"
            :peer-id="peerId"
            :peer-state="peerState[peerId]"
            :info="identities[peerId]"
            :moderator="iAmModerator" />
        </ol>

        <div v-else class="bg-gray-100 overflow-hidden rounded-lg">
          <div class="px-4 py-5 sm:p-6 text-center">
            <UserGroupIcon class="mx-auto h-8 w-8 text-gray-400" />
            <span class="mt-2 block text-sm font-medium text-gray-900">
              No one is in the audience
            </span>
          </div>
        </div>
      </div>
    </div>
  </Container>
</template>

<script setup>
import Bowser from 'bowser';
import { computed, toRefs } from 'vue'
import { InformationCircleIcon, ExclamationCircleIcon } from '@heroicons/vue/solid'
import { VolumeOffIcon, UserGroupIcon } from '@heroicons/vue/outline'
import { useJam } from '../lib/use-jam'

import EnterRoom from './EnterRoom.vue'
import Container from './Container.vue'
import RoomHeader from './RoomHeader.vue'
import StageAvatar from './StageAvatar.vue'
import AudienceAvatar from './AudienceAvatar.vue'
import Navigation from './Navigation.vue'
import CtaBtn from './CtaBtn.vue'
import WebViewTip from '../discovery/WebViewTip.vue'
import RoomClosedTip from '../discovery/RoomClosedTip.vue'
import PermissionsTip from '../discovery/PermissionsTip.vue'

const { state } = useJam()

const props = defineProps(['roomId', 'uxConfig'])
const { roomId, uxConfig } = toRefs(props)

const {
  reactions,
  handRaised,
  identities,
  speaking,
  iAmSpeaker,
  iAmModerator,
  myIdentity,
  inRoom,
  peers,
  peerState,
  myPeerState,
  hasMicFailed,
  room,
} = toRefs(state)

const { noLeave } = uxConfig

const userAgent = Bowser.parse(window.navigator.userAgent)
const inWebView = computed(() => 
  userAgent.browser.name === 'Chrome WebView' ||
   (userAgent.os.name === 'iOS' && userAgent.browser.name !== 'Mobile Safari'))

const myInfo = computed(() => myIdentity.value.info)
const hasEnteredRoom = computed(() => inRoom.value === roomId.value)

const myPeerId = computed(() => myInfo.value.id)
const stagePeers = computed(() => 
  room.value.stageOnly
  ? peers.value
  : (room.value.speakers ?? []).filter(id => peers.value.includes(id)))
const audiencePeers = computed(() =>
  room.value.stageOnly
  ? []
  : peers.value.filter(id => !stagePeers.value.includes(id)))


import { Menu, MenuButton, MenuItem, MenuItems } from '@headlessui/vue'
import { DotsVerticalIcon, BadgeCheckIcon } from '@heroicons/vue/solid'
import { HandIcon, CollectionIcon, CogIcon } from '@heroicons/vue/outline'
</script>