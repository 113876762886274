<template>
  <svg id="Layer_1" version="1.1" viewBox="0 0 1024 1024" xml:space="preserve" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" fill="currentColor">
    <g id="XMLID_35_">
      <polygon id="XMLID_437_" points="360.1,175.1 306.5,359.1 63,359.1 184.2,175.1  "/>
      <polygon id="XMLID_430_" points="307.2,399.1 459.8,820.7 70.8,399.1  "/>
      <polygon id="XMLID_402_" points="718.3,399.1 953.3,399.1 565.5,820.3  "/>
      <polygon id="XMLID_403_" points="719.1,359.1 665.5,175.1 839.8,175.1 961,359.1  "/>
      <polygon id="XMLID_378_" points="675.8,399.1 530.8,798.6 512.6,848.9 494.3,798.5 349.8,399.1  "/>
      <polygon id="XMLID_377_" points="677.4,359.1 348.1,359.1 401.7,175.1 623.8,175.1  "/>
    </g>
    <g id="XMLID_1_"/>
    <g id="XMLID_2_"/>
    <g id="XMLID_3_"/>
    <g id="XMLID_4_"/>
    <g id="XMLID_5_"/>
  </svg>
</template>