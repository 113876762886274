<!-- This example requires Tailwind CSS v2.0+ -->
<template>
  <TransitionRoot as="template" :show="open">
    <Dialog as="div" class="z-10 fixed inset-0 overflow-hidden" @close="open = false">
      <div class="absolute inset-0 overflow-hidden">
        <TransitionChild as="template" enter="ease-in-out duration-500" enter-from="opacity-0" enter-to="opacity-100" leave="ease-in-out duration-500" leave-from="opacity-100" leave-to="opacity-0">
          <DialogOverlay class="absolute inset-0 bg-gray-900 bg-opacity-60 transition-opacity" />
        </TransitionChild>

        <div class="fixed inset-y-0 right-0 max-w-full flex sm:pl-16">
          <TransitionChild as="template" enter="transform transition ease-in-out duration-500 sm:duration-700" enter-from="translate-x-full" enter-to="translate-x-0" leave="transform transition ease-in-out duration-500 sm:duration-700" leave-from="translate-x-0" leave-to="translate-x-full">
            <div class="w-screen max-w-md">
              <div class="h-full flex flex-col bg-white shadow-lg overflow-y-scroll">
                <div class="bg-gradient-to-r from-pink-600 to-purple-600 h-2"></div>
                <div class="px-6 pt-3 pb-4 bg-white shadow">
                  <div class="flex items-center justify-between">
                    <DialogTitle class="text-xl font-bold text-gray-900 sm:text-2xl">
                      Room Settings
                    </DialogTitle>
                    <div class="ml-3 h-7 flex items-center space-x-4">
                      <button @click="open = false" type="button" class="rounded-md text-gray-400 hover:text-gray-500 focus:ring-2 focus:ring-purple-600">
                        <span class="sr-only">Close panel</span>
                        <XIcon class="h-6 w-6" aria-hidden="true" />
                      </button>
                    </div>
                  </div>
                </div>
                <div class="p-4 sm:p-6 space-y-8">
                  <div>
                    <label for="topic" class="block text-sm font-medium text-gray-700">Topic</label>
                    <div class="mt-1">
                      <input type="text" id="topic" v-model="room.name" class="shadow-sm focus:ring-purple-500 focus:border-purple-500 block w-full sm:text-sm border-gray-300 rounded-md" />
                    </div>
                    <p class="mt-2 text-sm text-gray-500">
                      Pick a topic for the room to talk about.
                    </p>
                  </div>

                  <div class="grid grid-cols-2 gap-4">
                    <div>
                      <label for="button-text" class="block text-sm font-medium text-gray-700">Button text</label>
                      <div class="mt-1">
                        <input type="text" id="button-text" v-model="room.buttonText" class="shadow-sm focus:ring-purple-500 focus:border-purple-500 block w-full sm:text-sm border-gray-300 rounded-md" />
                      </div>
                    </div>

                    <div>
                      <label for="button-url" class="block text-sm font-medium text-gray-700">Button link</label>
                      <div class="mt-1">
                        <input type="url" id="button-url" v-model="room.buttonURI" class="shadow-sm focus:ring-purple-500 focus:border-purple-500 block w-full sm:text-sm border-gray-300 rounded-md" />
                      </div>
                    </div>
                  </div>

                  <div class="relative flex items-start">
                    <div class="flex items-center h-5">
                      <input id="comments" v-model="room.closed" aria-describedby="comments-description" name="comments" type="checkbox" class="focus:ring-purple-500 h-4 w-4 text-purple-600 border-gray-300 rounded" />
                    </div>
                    <div class="ml-3 text-sm">
                      <label for="comments" class="font-medium text-gray-700">Close the room</label>
                      <p class="text-gray-500">Closed rooms can only be joined by moderators. Everyone else sees the description, button and schedule.</p>
                    </div>
                  </div>

                  <div class="relative flex items-start">
                    <div class="flex items-center h-5">
                      <input id="stage-only" v-model="room.stageOnly" type="checkbox" class="focus:ring-purple-500 h-4 w-4 text-purple-600 border-gray-300 rounded" />
                    </div>
                    <div class="ml-3 text-sm">
                      <label for="stage-only" class="font-medium text-gray-700">Stage only</label>
                      <p class="text-gray-500">All participants will be on the stage.</p>
                    </div>
                  </div>

                  <div>
                    <label for="comments" class="font-medium text-sm text-gray-700">
                      <span class="text-base mr-1.5">📅</span>
                      Room schedule
                    </label>
                    <p class="mt-1 text-sm text-gray-500">
                      Set the date and time for an upcoming event.
                    </p>
                    <div class="mt-2 grid grid-cols-3 gap-4">
                      <div class="col-span-2 relative rounded-md shadow-sm">
                        <div class="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
                          <CalendarIcon class="h-5 w-5 text-gray-400" aria-hidden="true" />
                        </div>
                        <input type="date" name="email" id="email" class="focus:ring-purple-500 focus:border-purple-500 block w-full sm:text-sm border-gray-300 rounded-md" placeholder="you@example.com" />
                      </div>
                      <div class="relative rounded-md shadow-sm">
                        <div class="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
                          <ClockIcon class="h-5 w-5 text-gray-400" aria-hidden="true" />
                        </div>
                        <input type="time" name="email" id="email" class="focus:ring-purple-500 focus:border-purple-500 block w-full sm:text-sm border-gray-300 rounded-md" placeholder="you@example.com" />
                      </div>
                    </div>
                    <select id="location" name="location" class="mt-3 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-purple-500 focus:border-purple-500 sm:text-sm rounded-md">
                      <option>United States</option>
                      <option selected="">Canada</option>
                      <option>Mexico</option>
                    </select>
                  </div>

                  <div class="grid grid-cols-3 gap-4">
                    <div class="col-span-2">
                      <DBtn @click="submitUpdate" color="purple" :loading="loading" lg primary block>
                        Save
                      </DBtn>
                    </div>
                    <div class="col-span-1">
                      <DBtn @click="cancelUpdate" color="purple" lg secondary block>
                        Cancel
                      </DBtn>
                    </div>
                  </div>

                  <div>
                    <label for="comments" class="font-medium text-sm text-gray-700">
                      <span class="text-base mr-1.5">🔗</span>
                      Sharing
                    </label>
                    <div class="mt-2 space-y-4">
                      <div>
                        <div>
                          <pre class="shadow-sm border truncate p-2 hover:border-purple-500 block w-full sm:text-sm border-gray-300 rounded-md">https://talk.diamondhandshotel.com/{{ roomId }}</pre>
                        </div>
                        <p class="mt-2 text-sm text-gray-500">
                          Link to this room directly. Will bypass all room joining restrictions.
                        </p>
                      </div>
                      <div>
                        <div>
                          <pre class="shadow-sm border truncate p-2 hover:border-purple-500 block w-full sm:text-sm border-gray-300 rounded-md">{{ `<iframe src="https://talk.diamondhandshotel.com/${roomId}" allow="microphone *;" width="420" height="600"></iframe>` }}</pre>
                        </div>
                        <p class="mt-2 text-sm text-gray-500">
                          Embed room using an iFrame. Will bypass all room joining restrictions.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </TransitionChild>
        </div>
      </div>
    </Dialog>
  </TransitionRoot>
</template>

<script setup>
import { computed, toRefs, ref, watch } from 'vue'
import {
  Dialog,
  DialogOverlay,
  DialogTitle,
  Menu,
  MenuButton,
  MenuItem,
  MenuItems,
  TransitionChild,
  TransitionRoot,
} from '@headlessui/vue'
import { useStore } from 'vuex'
import { notify } from '@kyvg/vue3-notification'
import { XIcon } from '@heroicons/vue/outline'
import { CalendarIcon, ClockIcon } from '@heroicons/vue/solid'
import { DBtn } from '@ui'
import { useJam } from './lib/use-jam'

const { state, api } = useJam()
const { roomId, room: _room } = toRefs(state)
const room = ref({})

const store = useStore()
const open = computed({
  get: () => store.getters['talk/editRoomModal'],
  set: (val) => store.commit('talk/MODAL_EDIT_ROOM', val)
})

// Update room value when opened
watch(open, () => room.value = { ..._room.value })

const loading = ref(false)
const submitUpdate = async () => {
  loading.value = true
  try {
    await api.updateRoom(roomId.value, { ..._room.value, ...room.value })
    open.value = false
  } catch (err) {
    notify({ type: 'error', text: err.message })
  } finally {
    loading.value = false
  }
};

const cancelUpdate = () => {
  room.value = { ..._room.value }
  open.value = false
}
</script>

<style>
input[type="date"]::-webkit-inner-spin-button,
input[type="date"]::-webkit-calendar-picker-indicator,
input[type="time"]::-webkit-calendar-picker-indicator {
  display: none;
  -webkit-appearance: none;
}
</style>