<template>
  <div class="py-1">
    <MenuItem v-slot="{ active }">
      <button @click="editOpen = true" :class="[active ? 'bg-gray-100 text-gray-900' : 'text-gray-700', 'group flex items-center px-4 py-2 text-xs block w-full']">
        <CogIcon class="mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500" aria-hidden="true" />
        Edit Name
      </button>
    </MenuItem>
  </div>
</template>

<script setup>
import { toRefs, computed } from 'vue'
import { useStore } from 'vuex'
import { MenuItem } from '@headlessui/vue'
import { CogIcon } from '@heroicons/vue/outline'
import { useJam } from '../lib/use-jam'

const props = defineProps(['peerId'])
const { state } = useJam()
const { roomId } = toRefs(state)
const { peerId } = toRefs(props)

const store = useStore()
const editOpen = computed({
  get: () => store.getters['talk/editIdentityModal'],
  set: (val) => store.commit('talk/MODAL_IDENTITY', val)
})

</script>