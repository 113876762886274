<template>
  <ApolloQuery
    :query="listRooms"
    :variables="{ limit: 4 }"
    fetchPolicy="cache-and-network"
    v-slot="{ result: { data }, isLoading }" class="flex-1 px-4 sm:px-6 overflow-y-auto">
    <div class="flex-1 space-y-4">
      <template v-if="isLoading">
        <div class="bg-white shadow rounded-lg overflow-hidden">
          <div class="h-32 overflow-hidden">
            <div class="bg-gray-200 animate-pulse h-full"></div>
          </div>
          <div class="flex z-10 justify-center px-5 -mt-20">
            <div class="z-10 h-32 w-32 bg-gray-300 p-2 rounded-full"></div>
          </div>
          <div class="mt-2">
            <div class="px-14">
              <div class="bg-gray-200 h-8 w-36 rounded mx-auto"></div>
              <div class="mt-2 bg-gray-200 h-4 w-32 rounded mx-auto"></div>
            </div>
            <hr class="mt-6" />
            <div class="flex divide-x divide-gray-200">
              <div class="flex-1 flex bg-gray-50 text-gray-700 font-semibold p-6"></div>
              <div class="flex-1 flex bg-gray-50 text-gray-700 font-semibold p-6"></div>
            </div>
          </div>
        </div>
      </template>
      <template v-else>
        <div v-for="room in data.rooms" :key="room.id" class="bg-white shadow rounded-lg overflow-hidden">
          <div class=" h-32 overflow-hidden">
            <img class="w-full" :src="room.drop.coverImg" alt="" />
          </div>
          <div class="flex justify-center px-5 -mt-20">
            <img class="h-32 w-32 bg-white p-2 rounded-full" :src="room.drop.avatarImg" alt="" />
          </div>
          <div class=" ">
            <div class="text-center px-14">
              <h2 class="text-gray-800 text-3xl font-bold">{{ room.name }}</h2>
              <div class="mt-2 flex justify-center items-center space-x-2">
                <span v-if="room.casual" class="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-green-100 text-green-800">
                  Casual
                </span>
                <span class="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-gray-100 text-gray-800">
                  Public
                </span>
              </div>
              <p v-if="room.description" class="mt-2 text-gray-600">{{ room.description }}</p>
            </div>
            <hr class="mt-6" />
            <div class="flex divide-x divide-gray-200">
              <div class="flex-1 flex justify-center items-center bg-gray-50 text-gray-700 font-semibold p-4">
                <UsersIcon class="h-4 w-4 mr-2" />
                {{ room.stats.members }} talking
              </div>
              <div @click="join(room)" class="flex-1 font-medium flex justify-center items-center bg-pink-100 bg-opacity-50 text-pink-700 hover:bg-pink-200 cursor-pointer p-4">
                <span>Join booth</span>
                <ArrowRightIcon class="h-4 w-4 ml-2" />
              </div>
            </div>
          </div>
        </div>
      </template>
    </div>
  </ApolloQuery>
</template>

<script setup>
import { listRooms } from '@/gql/talk'
import { UsersIcon, ArrowRightIcon } from '@heroicons/vue/solid'
import { useJam } from '../lib/use-jam'

const { state, api } = useJam()
const join = async (room) => {
  api.createRoom(room.id, {
    name: room.name,
    stageOnly: room.stageOnly,
    autoJoin: room.autoJoin,
  });

  api.setProps({ roomId: room.id })
  await api.enterRoom(room.id)
}
</script>