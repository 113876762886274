<!-- This example requires Tailwind CSS v2.0+ -->
<template>
  <div class="h-full flex bg-white">
    <TransitionRoot as="template" :show="sidebarOpen">
      <Dialog as="div" class="fixed inset-0 flex z-40 lg:hidden" @close="sidebarOpen = false">
        <TransitionChild as="template" enter="transition-opacity ease-linear duration-300" enter-from="opacity-0" enter-to="opacity-100" leave="transition-opacity ease-linear duration-300" leave-from="opacity-100" leave-to="opacity-0">
          <DialogOverlay class="fixed inset-0 bg-gray-600 bg-opacity-75" />
        </TransitionChild>
        <TransitionChild as="template" enter="transition ease-in-out duration-300 transform" enter-from="-translate-x-full" enter-to="translate-x-0" leave="transition ease-in-out duration-300 transform" leave-from="translate-x-0" leave-to="-translate-x-full">
          <div class="relative flex-1 flex flex-col max-w-xs w-full bg-gray-100 focus:outline-none">
            <TransitionChild as="template" enter="ease-in-out duration-300" enter-from="opacity-0" enter-to="opacity-100" leave="ease-in-out duration-300" leave-from="opacity-100" leave-to="opacity-0">
              <div class="absolute top-0 right-0 -mr-12 pt-2">
                <button type="button" class="ml-1 flex items-center justify-center h-10 w-10 rounded-full focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white" @click="sidebarOpen = false">
                  <span class="sr-only">Close sidebar</span>
                  <XIcon class="h-6 w-6 text-white" aria-hidden="true" />
                </button>
              </div>
            </TransitionChild>
            <div class="relative overflow-hidden">
              <img class="absolute inset-0 object-cover object-center w-full h-full" src="@/assets/img/cover-outer.png" />
              <div class="relative bg-gradient-to-b from-gray-900 to-transparent px-6 pt-3 pb-10">
                <div class="text-2xl font-extrabold tracking-wide text-white">The Roof</div>
              </div>
            </div>
            <!-- Sidebar component, swap this element with another sidebar if you like -->
            <div class="mt-4 h-0 flex-1 flex flex-col overflow-y-auto">
              <!-- User account dropdown -->
              <UserSelector />

              <!-- Navigation -->
              <nav class="px-3 mt-6">
                <div class="space-y-1">
                  <AppLink v-for="item in navigation" :key="item.name" :to="item.href" :exact="item.exact" active-class="bg-gray-200 text-gray-900" class="text-gray-700 hover:text-gray-900 hover:bg-gray-50 group flex items-center px-2 py-2 text-sm font-medium rounded-md" v-slot="{ active }">
                    <component :is="item.icon" :class="[active ? item.color : 'text-gray-400 group-hover:text-gray-500', 'mr-3 flex-shrink-0 h-6 w-6']" aria-hidden="true" />
                    {{ item.name }}

                    <span v-if="item.locked" class="ml-auto inline-block">
                      <LockClosedIcon class="h-4 w-4 text-yellow-500" />
                    </span>
                  </AppLink>
                </div>
                <div class="mt-8 space-y-2">
                  <h3 class="px-3 text-xs font-semibold text-gray-500 uppercase tracking-wider" id="desktop-teams-headline">
                    Experience
                  </h3>
                  <SidebarBtn v-model:sidebar-open="sidebarOpen" />
                </div>
                <div class="mt-8 space-y-2">
                  <!-- Secondary navigation -->
                  <h3 class="px-3 text-xs font-semibold text-gray-500 uppercase tracking-wider" id="desktop-teams-headline">
                    Latest Drops
                  </h3>
                  <div class="space-y-1" role="group" aria-labelledby="desktop-teams-headline">
                    <AppLink v-for="team in teams" :key="team.name" :to="team.href" active-class="text-gray-900 bg-gray-50" class="group flex items-center px-3 py-2 text-sm font-medium text-gray-700 rounded-md hover:text-gray-900 hover:bg-gray-50">
                      <span :class="[team.bgColorClass, 'w-2.5 h-2.5 mr-4 rounded-full']" aria-hidden="true" />
                      <span class="truncate">
                        {{ team.name }}
                      </span>
                    </AppLink>
                  </div>
                </div>
                <div class="mt-8 space-y-2">
                  <h3 class="px-3 text-xs font-semibold text-gray-500 uppercase tracking-wider" id="projects-headline">
                    More from The Hotel
                  </h3>
                  <div class="space-y-1" role="group" aria-labelledby="projects-headline">
                    <a href="https://diamondhandshotel.com/lobby" target="_blank" class="group flex items-center px-3 py-2 text-sm font-medium text-gray-600 rounded-md hover:text-gray-900 hover:bg-gray-50">
                      <InformationCircleIcon class="w-3.5 h-3.5 mr-3" />
                      <span class="truncate">
                        About us
                      </span>
                    </a>
                    <a href="https://blog.diamondhandshotel.com" target="_blank" class="group flex items-center px-3 py-2 text-sm font-medium text-gray-600 rounded-md hover:text-gray-900 hover:bg-gray-50">
                      <RssIcon class="w-3.5 h-3.5 mr-3" />
                      <span class="truncate">
                        The Back Office 
                      </span>
                    </a>
                    <a href="https://twitter.com/diamondhh" target="_blank" class="group flex items-center px-3 py-2 text-sm font-medium text-gray-600 rounded-md hover:text-gray-900 hover:bg-gray-50">
                      <TwitterIcon class="w-3.5 h-3.5 mr-3" />
                      <span class="truncate">
                        @DiamondHH
                      </span>
                    </a>
                    <a href="https://discord.gg/4SSJWSdsUk" target="_blank" class="group flex items-center px-3 py-2 text-sm font-medium text-gray-600 rounded-md hover:text-gray-900 hover:bg-gray-50">
                      <DiscordIcon class="w-3.5 h-3.5 mr-3" />
                      <span class="truncate">
                        Join Discord
                      </span>
                    </a>
                    <a href="https://blog.diamondhandshotel.com" target="_blank" class="group flex items-center px-3 py-2 text-sm font-medium text-gray-600 rounded-md hover:text-gray-900 hover:bg-gray-50">
                      <SupportIcon class="w-3.5 h-3.5 mr-3" />
                      <span class="truncate">
                        Support
                      </span>
                    </a>
                  </div>
                </div>
              </nav>
            </div>
          </div>
        </TransitionChild>
        <div class="flex-shrink-0 w-14" aria-hidden="true">
          <!-- Force sidebar to shrink to fit close icon -->
        </div>
      </Dialog>
    </TransitionRoot>

    <!-- Static sidebar for desktop -->
    <div class="hidden z-10 lg:flex lg:flex-col lg:w-64 lg:fixed lg:inset-y-0 lg:pb-4 lg:bg-gray-100">
      <div class="relative overflow-hidden">
        <img class="absolute inset-0 object-cover object-center w-full h-full" src="@/assets/img/cover-outer.png" />
        <div class="relative bg-gradient-to-b from-gray-900 to-transparent px-6 pt-3 pb-16">
          <div class="text-2xl font-extrabold tracking-wide text-white">The Roof</div>
          <div class="text-sm font-medium text-pink-200">Diamond Hands Hotel</div>
        </div>
      </div>
      <!-- Sidebar component, swap this element with another sidebar if you like -->
      <div class="mt-3 h-0 flex-1 flex flex-col overflow-y-auto">
        <!-- User account dropdown -->
        <UserSelector />

        <!-- Navigation -->
        <nav class="px-3 mt-6">
          <div class="space-y-1">
            <AppLink v-for="item in navigation" :key="item.name" :to="item.href" :exact="item.exact" active-class="bg-gray-200 text-gray-900" class="text-gray-700 hover:text-gray-900 hover:bg-gray-50 group flex items-center px-2 py-2 text-sm font-medium rounded-md" v-slot="{ active }">
              <component :is="item.icon" :class="[active ? item.color : 'text-gray-400 group-hover:text-gray-500', 'mr-3 flex-shrink-0 h-6 w-6']" aria-hidden="true" />
              {{ item.name }}

              <span v-if="item.locked" class="ml-auto inline-block">
                <LockClosedIcon class="h-4 w-4 text-yellow-500" />
              </span>
            </AppLink>
          </div>

          <div class="mt-8 space-y-2">
            <h3 class="px-3 text-xs font-semibold text-gray-500 uppercase tracking-wider" id="desktop-teams-headline">
              Experience
            </h3>
            <SidebarBtn v-model:sidebar-open="sidebarOpen" />
          </div>

          <div class="mt-8 space-y-2">
            <!-- Secondary navigation -->
            <h3 class="px-3 text-xs font-semibold text-gray-500 uppercase tracking-wider" id="desktop-teams-headline">
              Latest Drops
            </h3>
            <div class="space-y-1" role="group" aria-labelledby="desktop-teams-headline">
              <AppLink v-for="team in teams" :key="team.name" :to="team.href" active-class="text-gray-900 bg-gray-50" class="group flex items-center px-3 py-2 text-sm font-medium text-gray-700 rounded-md hover:text-gray-900 hover:bg-gray-50">
                <span :class="[team.bgColorClass, 'w-2.5 h-2.5 mr-4 rounded-full']" aria-hidden="true" />
                <span class="truncate">
                  {{ team.name }}
                </span>
              </AppLink>
            </div>
          </div>
          <div class="mt-8 space-y-2">
            <h3 class="px-3 text-xs font-semibold text-gray-500 uppercase tracking-wider" id="projects-headline">
              More from The Hotel
            </h3>
            <div class="space-y-1" role="group" aria-labelledby="projects-headline">
              <a href="https://diamondhandshotel.com/lobby" target="_blank" class="group flex items-center px-3 py-2 text-sm font-medium text-gray-600 rounded-md hover:text-gray-900 hover:bg-gray-50">
                <InformationCircleIcon class="w-3.5 h-3.5 mr-3" />
                <span class="truncate">
                  About us
                </span>
              </a>
              <a href="https://blog.diamondhandshotel.com" target="_blank" class="group flex items-center px-3 py-2 text-sm font-medium text-gray-600 rounded-md hover:text-gray-900 hover:bg-gray-50">
                <RssIcon class="w-3.5 h-3.5 mr-3" />
                <span class="truncate">
                  The Back Office 
                </span>
              </a>
              <a href="https://twitter.com/diamondhh" target="_blank" class="group flex items-center px-3 py-2 text-sm font-medium text-gray-600 rounded-md hover:text-gray-900 hover:bg-gray-50">
                <TwitterIcon class="w-3.5 h-3.5 mr-3" />
                <span class="truncate">
                  @DiamondHH
                </span>
              </a>
              <a href="https://discord.gg/4SSJWSdsUk" target="_blank" class="group flex items-center px-3 py-2 text-sm font-medium text-gray-600 rounded-md hover:text-gray-900 hover:bg-gray-50">
                <DiscordIcon class="w-3.5 h-3.5 mr-3" />
                <span class="truncate">
                  Join Discord
                </span>
              </a>
              <a href="https://blog.diamondhandshotel.com" target="_blank" class="group flex items-center px-3 py-2 text-sm font-medium text-gray-600 rounded-md hover:text-gray-900 hover:bg-gray-50">
                <SupportIcon class="w-3.5 h-3.5 mr-3" />
                <span class="truncate">
                  Support
                </span>
              </a>
            </div>
          </div>
        </nav>
      </div>
    </div>

    <div class="lg:pl-64 relative flex flex-col min-w-0 flex-1 overflow-hidden">
      <div class="lg:hidden">
        <div class="absolute inset-x-0 z-10 flex items-center justify-between bg-white bg-opacity-50 backdrop-filter backdrop-blur shadow px-4 py-1">
          <div>
            <span class="text-2xl font-extrabold text-gray-700">
              The Roof
            </span>
          </div>
          <div>
            <button type="button" class="-mr-3 h-12 w-12 inline-flex items-center justify-center rounded-md text-gray-500 hover:text-gray-900" @click="sidebarOpen = true">
              <span class="sr-only">Open sidebar</span>
              <MenuIcon class="h-6 w-6" aria-hidden="true" />
            </button>
          </div>
        </div>
      </div>
      <div class="flex-1 relative z-0 flex overflow-hidden">
        <main ref="scrollElem" class="pt-14 lg:pt-0 flex-1 relative z-0 overflow-y-auto focus:outline-none">
          <router-view />
        </main>
        <aside v-if="sidebar" class="hidden relative xl:flex xl:flex-col flex-shrink-0 w-96 border-l border-gray-200">
          <router-view name="right" />
        </aside>
      </div>
    </div>

    <TalkSidebar />
  </div>
</template>

<script setup>
import { Dialog, DialogOverlay, TransitionChild, TransitionRoot, Menu, MenuButton, MenuItems, MenuItem } from '@headlessui/vue'
import { BellIcon, MenuIcon, XIcon, HomeIcon, CollectionIcon, CubeIcon, PuzzleIcon, MapIcon, UserCircleIcon, SelectorIcon, BadgeCheckIcon, VolumeUpIcon } from '@heroicons/vue/outline'
import { SearchIcon, RssIcon, SupportIcon, InformationCircleIcon, LockClosedIcon } from '@heroicons/vue/solid'
import { TwitterIcon, DiscordIcon } from '@ui'
import { useRoute } from 'vue-router'
import { computed, ref, provide, onMounted } from 'vue'

import AppLink from '@/components/roof/AppLink.vue'
import UserSelector from '@/components/roof/user/UserSelector.vue'
// import Notifications from '@/components/roof/user/Notifications.vue'
// import ProfileDropdown from '@/components/roof/user/ProfileDropdown.vue'
// import ProfileListItem from '@/components/roof/user/ProfileListItem.vue'
// import ConnectWalletModal from '@/components/roof/user/ConnectWalletModal.vue'

const route = useRoute()
const sidebar = computed(() => route.meta.sidebar)
// const reverse = computed(() => route.meta.reverse)

const sidebarOpen = ref(false)
const navigation = [
  { name: 'Home', exact: true, icon: HomeIcon, color: 'text-yellow-500', locked: true, href: { name: 'Roof' } },
  { name: 'VIP Rooms', icon: BadgeCheckIcon, color: 'text-yellow-500', locked: true, href: { name: 'Rooms' } },
  { name: 'Wallet', icon: CollectionIcon, color: 'text-green-500', href: { name: 'Wallet' } },
  { name: 'Drops', icon: CubeIcon, color: 'text-pink-500', href: { name: 'Explore' } },
  { name: 'Games', icon: PuzzleIcon, color: 'text-purple-500', href: { name: 'Games' } },
  { name: 'Roadmap', icon: MapIcon, color: 'text-yellow-500', href: 'https://twitter.com/SpaceSneksNFT/status/1467911800427794432' },
  // { name: 'Graffiti Wall', icon: HomeIcon, href: { name: 'GraffitiWall' } },
  { name: 'Account', icon: UserCircleIcon, color: 'text-blue-500', href: { name: 'Account' } },
]
const userNavigation = [
  { name: 'Your Profile', href: { name: 'Roof' }, },
  { name: 'Settings', href: { name: 'Roof' } },
  { name: 'Sign out', href: { name: 'Roof' } },
];

const teams = [
  { name: 'Space Sneks', href: { name: 'ExploreDrop', params: { slug: 'spacesneks' } }, bgColorClass: 'bg-green-500' },
  { name: 'The Keycard Games', href: { name: 'ExploreDrop', params: { slug: 'keycards' } }, bgColorClass: 'bg-blue-500' },
]

const scrollElem = ref(null)
const lastScrollPos = ref(0)
const collapseNavbar = ref(false)
const onScroll = () => {
  console.log('on scroll called')
  const currentScrollPosition = scrollElem.value.scrollTop
  if (currentScrollPosition < 0) {
    return
  }

  // Stop executing this function if the difference between
  // current scroll position and last scroll position is less than some offset
  if (Math.abs(currentScrollPosition - lastScrollPos.value) < 60) {
    return
  }

  collapseNavbar.value = currentScrollPosition > lastScrollPos.value
  lastScrollPos.value = currentScrollPosition
}

onMounted(() => {
  console.log('on mounted called')
  scrollElem.value.addEventListener('scroll', onScroll)
})

provide('collapseNavbar', collapseNavbar)


// todo: tidy
import TalkSidebar from '@/components/roof/talk/TalkSidebar.vue'
import SidebarBtn from '@/components/roof/talk/SidebarBtn.vue'  
</script>