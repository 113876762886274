<template>
  <div v-if="iAmSpeaker && !micOn" class="bg-red-50 p-4 sm:px-6">
    <div class="flex">
      <div class="flex-shrink-0">
        <ExclamationIcon class="h-5 w-5 text-red-700" aria-hidden="true" />
      </div>
      <div class="ml-3 flex-1 md:flex md:justify-between">
        <p class="text-sm text-red-700">
          Grant microphone permission to talk
        </p>
        <p class="mt-3 text-sm md:mt-0 md:ml-6">
          <a href="https://support.google.com/chrome/answer/2693767?hl=en&co=GENIE.Platform%3DDesktop" target="_blank" class="whitespace-nowrap font-medium text-red-700 hover:text-red-600">Help <span aria-hidden="true">&rarr;</span></a>
        </p>
      </div>
    </div>
  </div>
</template>

<script setup>
import { computed, toRefs } from 'vue'
import { ExclamationIcon } from '@heroicons/vue/outline'
import { useJam } from '../lib/use-jam'

const { state, api } = useJam()
const { iAmSpeaker, myAudio } = toRefs(state)

const micOn = computed(() => myAudio.value?.active)
</script>