import {toBytes, toBase64} from 'fast-base64/base64-js';
import {toUrl, fromUrl} from 'fast-base64/url';

export { decode, encode }

function decode(base64String) {
  return toBytes(fromUrl(base64String));
}

function encode(binaryData) {
  return toUrl(toBase64(binaryData));
}