<template>
  <div v-if="inWebView" class="bg-gray-50 p-4 sm:px-6">
    <div class="flex">
      <div class="flex-shrink-0">
        <ExternalLinkIcon class="h-5 w-5 text-gray-700" aria-hidden="true" />
      </div>
      <div class="ml-3 flex-1 md:flex md:justify-between">
        <p class="text-sm text-gray-700">
          Open in {{ userAgent.os.name === 'iOS' ? 'Safari' : 'Chrome' }} for the best experience
        </p>
      </div>
    </div>
  </div>
</template>

<script setup>
import Bowser from 'bowser'
import { computed } from 'vue'
import { ExternalLinkIcon } from '@heroicons/vue/outline'

const userAgent = Bowser.parse(window.navigator.userAgent)
const inWebView = computed(() => 
  userAgent.browser.name === 'Chrome WebView' ||
   (userAgent.os.name === 'iOS' && userAgent.browser.name !== 'Safari'))
</script>